.formsWrapper {
  .umbraco-forms-field-wrapper {
    width: 50%;
  }

  input,
  .iti {
    width: 100%;
  }

  .iti__country-name {
    display: block;
  }

  .iti__dial-code {
    display: inline-block;
    margin-left: 5px;
    /* Optional: Add some space between the flag and code */
  }

  .iti__flag {
    display: inline-block;
  }
}

#discuss-form,
#partner-form {
  z-index: 0;
  transform: translateX(100%); // Initially off-screen to the right
}

#discuss-form.form-active,
#partner-form.form-active {
  transform: translateX(0); // Slide in from the right
  z-index: 2; // Bring to the front
}


.partner-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 120px;
  position: relative;
}

.discuss-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

}

.form-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  margin-top: 120px;
  transition: all 0.3s ease;
  opacity: 1;

  @media (max-width: 1024px) {
    width: 75%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  &.disable {
    opacity: 0;
    pointer-events: none;
  }

  .form-heading-1 {
    font-size: 36px;
    text-align: center;
    font-weight: 400;

    h2 {
      font-size: inherit;
    }
  }

  .select-container {
    width: 100%;
    height: 65px;
    border: 1px solid $white-300;
    border-radius: 4px 4px 0px 0px;

    .select-btn {
      padding: 24px;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        padding: 20px;
        font-size: 12px;
      }

      i {
        transition: 0.2s all ease;
      }

      .sBtn-text.placeholder {
        color: $black-400;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .select-options {
      position: relative;
      z-index: 3;
      background-color: $white-200;

      ul {
        position: relative;
        list-style: none;
        padding: 0;
        border: 1px solid $white-300;
        font-size: 12px;
        border-radius: 0px 0px 4px 4px;
        display: none;
        max-height: 150px;
        overflow-y: auto;
        box-shadow: 5px 5px 15px 0px rgba(169, 168, 167, 0.39);

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #303030;
          background-clip: padding-box;
          border: 3px solid transparent;
          border-radius: 5px;
          height: 83px;
          cursor: default;
        }

        li {
          padding: 12px 24px;
          border-bottom: 1px solid $white-300;
          cursor: pointer;
          transition: 0.3s all ease;
        }

        li:last-child {
          border-bottom: none;
        }

        li:hover {
          background-color: $black-0;
        }
      }
    }

    &.active {
      border-color: $black-400;

      .select-options {
        ul {
          display: block;
        }
      }

      .select-btn i {
        transform: rotate(-180deg);
      }
    }
  }
}

.form-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 700px;
  position: absolute;
  z-index: 4;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s opacity ease;
  top: 0;

  &.connectsuccess {
    height: 500px;
  }

  &.active {
    pointer-events: initial;

    opacity: 1;
  }

  .success-icon {
    border-radius: 100%;
    border: 3px solid #5a9c4c;
    width: 61px;
    height: 61px;
    color: #5a9c4c;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 25px;
    }
  }

  .success-content {
    display: flex;
    flex-direction: column;
    gap: 48px;
    text-align: center;

    .success-text {
      h5 {
        font-size: 32px;
        font-weight: bold;
      }
      h6 {
        font-size: 24px;
      }
    }
  }
}
.form-fail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 700px;
  position: absolute;
  z-index: 4;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s opacity ease;
  top: 0;

  &.connectfail {
    height: 500px;
  }

  &.active {
    pointer-events: initial;

    opacity: 1;
  }

  .fail-icon {
    border-radius: 100%;
    border: 3px solid $primary;
    width: 61px;
    height: 61px;
    color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 25px;
    }
  }

  .fail-content {
    display: flex;
    flex-direction: column;
    gap: 48px;
    text-align: center;

    .fail-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;

      h5 {
        font-size: 32px;
        font-weight: bold;
      }
      h6 {
        font-size: 24px;
        max-width: 50%;
      }
    }
  }
}

@media (max-width: 768px) {
  .form-success {
    padding: 20px;
    top: 100px;
  }

  .form-fail {
    .fail-content {
      .fail-text {
        h6 {
          max-width: 95%;
        }
      }
    }
  }
}

.form-container {
  color: $black-400;
  font-family: "nb_international_prolight", sans-serif;
  font-weight: 400;
  width: 100%;

  .form-control {
    background-color: $white-200;
    color: $black-400;
    border-radius: 4px;
    padding: 24px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid $white-300;

    @media (max-width: 768px) {
      padding: 20px;
      font-size: 12px;
    }

    &::placeholder {
      color: $black-400;
      font-size: 14px;
      font-weight: 400;
    }

    &:focus {
      background-color: $white-200;
      border-color: $black-400;
      outline: none;
      box-shadow: none;
    }
  }

  .input-container {
    position: relative;

    .error-message {
      display: none;
      font-size: 10px;
      font-weight: bold;
      color: $redcarpet-800;
      position: absolute;
      bottom: -20px;

      &.show {
        display: block;
      }
    }
  }

  .input-wrapper {
    position: relative;

    .form-control {
      border-top: none;
      border-radius: 0px 0px 4px 4px;
    }

    &::after {
      content: "Optional";
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      color: $black-300;
      font-size: 12px;
      opacity: 0.8;
      pointer-events: none;
    }

    input {
      padding-right: 85px;
    }
  }

  .name {
    margin-bottom: 24px;
  }

  .phone-section-container {
    display: flex;
    gap: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .input-container {
      .email::placeholder {
        color: $black-200 !important;
        font-size: 14px !important;
        font-weight: 400 !important;
      }
    }

    .iti {
      flex: 50%;
      color: $black-400;

      font-size: 12px;
      position: relative;

      width: 100%;
      height: 100%;

      .iti__country-container {
        button {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: $black-400;
          padding: 24px;
          background-color: $white-200;
          padding-right: 50px;
          border-right: 1px solid $white-300;

          &:hover {
            background-color: $black-0;

            .iti__selected-country-primary {
              background-color: $black-0 !important;
            }
          }
        }
      }

      .iti__selected-country {
        position: relative;
      }

      .iti__arrow {
        position: absolute;
        right: 30px;
        top: 48%;
      }

      .iti__tel-input {
        border: none;
        height: 100%;
        padding: 24px;
        width: 100%;
        background-color: $white-200;
        padding-left: 162px !important;
        border-radius: 2px;
        outline: 1px solid $white-300;

        @media (max-width: 768px) {
          padding: 20px;
        }

        &::placeholder {
          color: $black-200;
          font-size: 14px;
          font-weight: 400;
          @media (max-width: 768px) {
            font-size:12px;
          }

        }

        &:focus {
          box-shadow: none;
          outline-color: $black-400;
        }
      }

      .iti__selected-country-primary {
        background-color: inherit;
        pointer-events: none;
      }

      .iti__search-input {
        padding: 14px;
        background-color: $white-200;
        border-bottom: 1px solid $white-300;
        &:focus {
          outline: none;
        }
      }

      .iti__country {
        padding: 14px 24px;
        border-bottom: 1px solid $white-300;
        transition: 0.3s all ease;
      }

      .iti__highlight {
        background-color: $black-0;
      }

      .iti__selected-country-primary {
        background-color: $white-200 !important;
      }
      .iti__dropdown-content {
        border-radius: 4px;
        background-color: $white-200;
        box-shadow: 5px 5px 15px 0px rgba(169, 168, 167, 0.39);
        .iti__country-list {
          position: relative;
          &::-webkit-scrollbar {
            width: 10px;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #303030;
            background-clip: padding-box;
            border: 3px solid transparent;
            border-radius: 5px;
            height: 83px;
            cursor: default;
          }
        }
      }
    }

    .input-container {
      flex: 50%;
    }

    .form-control {
      &::placeholder {
        color: $black-400;
        font-size: 12px;
      }

      &:focus {
        border-color: $black-400;
        outline: none;
        box-shadow: none;
      }
    }
  }

  .form-heading-2 {
    font-size: 22px;
    text-align: center;
    font-weight: 400;
    margin: 48px 0 24px 0;

    h3 {
      font-size: inherit;
    }
  }

  /////////////////////////////////////////////////////////////////////
  //divider to increase the whitespace height
  .form-section-divider {
    min-height: 200px;
    transition: all 0.3s ease;
  }

  //increase the whitespace height when clicking next
  .form-section-divider.steptwo {
    min-height: 300px;
  }

  //increase the total form height
  .form-container {
    min-height: 500px;
  }

  .form-textarea {
    resize: none;
    box-sizing: border-box;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
    width: 100%;
  }
}
