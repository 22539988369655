@use '../../custom' as *;

#statistics-container {
  margin-top: 120px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 992px) {
      gap: 24px;
    }

    .content-row {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-left: 0;
      margin-right: 0;
      width: 100%;

      @media (max-width: 992px) {
        gap: 24px;
      }

      .content-col {
        padding-left: 0;
        padding-right: 0;
        border-radius: 3px;
        border: 1px solid $white-300;
        height: 400px;
        text-align: center;
        color: $black-400;

        @media (max-width: 992px) {
          height: 160px;
          padding: 16px;
        }

        .content-stats {
          display: flex;
          font-size: 61px;
          margin-bottom: 48px;
          font-weight: 400;
          font-family: "nb_international_promono";

          @media (max-width: 992px) {
            font-size: 21px;
            margin-bottom: 8.91px;
          }
          .count-unit{
            vertical-align: baseline;
            font-size: 0.75em;

          }
        }

        .content-text {
          font-size: 18px;
          font-weight: bold;

          @media (max-width: 992px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .stats-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .statistics--title {
    max-width: 927px;
    margin-top: 96px;
    margin-bottom: 48px;
  }

  .counter-block {
    margin-top: 96px;
    margin-bottom: 120px;
    padding-left: 0;
    padding-right: 0;

  }

  @media (max-width: 992px) {
    .statistics--title {
      margin-top: 64px;
      max-width: 95%;
    }
    .counter-block {
      padding-left: 12px;
      padding-right: 12px;
      margin-top: 64px;
      margin-bottom: 80px;
    }
  }

}