@use 'custom' as *;

@import 'common';

@import 'home';
@import 'news-insights';
@import 'solutions';
@import 'portfolio';
@import 'product-listing';
@import 'product-details'; 
@import 'sustainability';
@import 'forms'; 
@import 'terms-conditions';
@import 'search-results';

@import 'about';
@import 'careers';
@import 'connect';
@import 'partners';
@import 'page-not-found';

//@import '../assets/fonts/nbint/nbint';
//@import '../assets/fonts/nbint_new/nbint';
@import '../assets/fonts/nbint_pro_web/nbint-pro-web';
@import '../assets/fonts/fontawesome/css/all';
@import '../icons-dist/icon.css';


@import 'lenis/dist/lenis.css';
@import 'intl-tel-input/build/css/intlTelInput.css';
@import '../../node_modules/swiper/swiper-bundle.min.css';

@import "./bootstrap";  

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  letter-spacing: -0.25px;
}

/* Remove default margin in favour of better control in authored CSS */
body, h1, h2, h3, h4, p,
figure, blockquote, dl, dd {
  margin-block-end: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}


/* Set shorter line heights on headings and interactive elements */
h1, h2, h3, h4,
button, input, label {
  line-height: 1.1;
}


/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input, button,
textarea, select {
  font-family: inherit;
  font-size: inherit;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

body {
    font-family: 'nb_international_prolight';
    background-color: $white-200;
    color: $black-400;
    line-height: 1.5;
    height: 100vh;
    overflow-x: hidden;
}