@use "../../custom" as *;

.search-section {
  margin-top: 225px;
  margin-bottom: 120px;

  @media (max-width: 768px) {
    margin-top: 150px;
    margin-bottom: 43px;
  }

  .search-page-title {
    margin-bottom: 48px;
    font-size: 47px;
    font-weight: 400;

    @media (max-width: 576px) {
      font-size: 27px;
      padding: 0px 50px;
    }
  }
  .search-container {
    font-size: 16px;
    color: $black-400;

    .search-results-form {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .dropdown-menu {
        width: 100%;
      }
    }

    .search-bar {
      width: 80%;
      display: flex;
      align-items: center;
      gap: 2px;
      border: 1px solid $white-300;
      border-radius: 4px;

      @media (max-width: 576px) {
        width: 100%;
      }
      .btn {
        &::after {
          display: none;
        }
      }

      .search-select-container {
        position: relative;
        border-right: 1px solid $white-300;
        .category-select {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background: none;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          padding: 24px 56px 24px 24px;
          font-size: 16px;
          @media (max-width: 768px) {
            font-size: 14px;
            padding: 20px 52px 20px 20px;
          }

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        .custom-select-icon {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;
        }
      }

      .form-control {
        border: none;
        padding: 20px;
        font-size: 16px;
        box-shadow: none;
        @media (max-width: 768px) {
          font-size: 14px;
        }
    }

      .search-icon {
        background-color: transparent;
        padding: 20px;
        border: none;
      }
    }
  }
}

.search-results-card-section {
  margin-bottom: 48px;
  @media (max-width: 768px) {
    margin-bottom:0px;
  }

  .custom-fs-1 {
    font-size: 36px;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  .custom-text-red {
    color: $redcarpet-700;
  }

  .search-list-grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    @media (max-width: 768px) {
      margin-left: -8px;
      margin-right: -8px;
    }

    .col-lg-4,
    .col-md-6 {
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 0px;
      margin-bottom: 20px;
      

      @media (max-width: 768px) {
        padding-left: 8px;
        padding-right: 8px;
        margin-bottom: 16px;
      }

      .solution-card {
        text-decoration: none !important;
        transition: all ease-out 0.5s;
        min-height: 388px;
        height: 100%;

        &:hover {
          box-shadow: 121px 146px 53px 0px rgba(0, 0, 0, 0),
            77px 93px 49px 0px rgba(0, 0, 0, 0.01),
            44px 53px 41px 0px rgba(0, 0, 0, 0.05),
            19px 23px 30px 0px rgba(0, 0, 0, 0.09),
            5px 6px 17px 0px rgba(0, 0, 0, 0.1);
        }

        .card-body {
          padding: 24px;
          @media (max-width: 768px) {
            padding: 20px;
          }

          .card-title {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 12px;
            color: $black-400;
            @media (max-width: 768px) {
              font-size: 16px;
            }
          }

          p {
            font-size: 16px;
            color: $black-300;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @media (max-width: 768px) {
              font-size: 14px;
            }
          }
        }

        img {
          width: 100%;
          height: 232px;
          object-fit: cover;
          @media (max-width: 768px) {
            height: 247px;
          }
        }
      }

      .product-card {
        text-decoration: none !important;
        transition: all ease-out 0.5s;
        height: 388px;
        &:hover {
          box-shadow: 121px 146px 53px 0px rgba(0, 0, 0, 0),
            77px 93px 49px 0px rgba(0, 0, 0, 0.01),
            44px 53px 41px 0px rgba(0, 0, 0, 0.05),
            19px 23px 30px 0px rgba(0, 0, 0, 0.09),
            5px 6px 17px 0px rgba(0, 0, 0, 0.1);
        }

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
        }

        .card-body {
          padding: 24px;
          .product-card-subtitle {
            font-size: 14px;
            color: $redcarpet-700;
            text-transform: uppercase;
            margin-bottom: 12px;

            @media (max-width: 768px) {
              font-size: 12px;
            }
          }
          .product-card-title {
            font-size: 22px;
            font-weight: bold;
            color: $black-400;
            letter-spacing: 0;
            line-height: 30px;

            @media (max-width: 768px) {
              font-size: 16px;
            }
          }

          .product-btn {
            margin-top: auto;
            text-transform: uppercase;
            :hover {
              box-shadow: 121px 146px 53px 0px rgba(0, 0, 0, 0),
                77px 93px 49px 0px rgba(0, 0, 0, 0.01),
                44px 53px 41px 0px rgba(0, 0, 0, 0.05),
                19px 23px 30px 0px rgba(0, 0, 0, 0.09),
                5px 6px 17px 0px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }

      .portfolio-card {
        text-decoration: none !important;
        transition: all ease-out 0.5s;
        height: 388px;
        &:hover {
          box-shadow: 121px 146px 53px 0px rgba(0, 0, 0, 0),
            77px 93px 49px 0px rgba(0, 0, 0, 0.01),
            44px 53px 41px 0px rgba(0, 0, 0, 0.05),
            19px 23px 30px 0px rgba(0, 0, 0, 0.09),
            5px 6px 17px 0px rgba(0, 0, 0, 0.1);
        }

        img {
          padding:48px;
          width: 100%;
          height: 293px;
          object-fit: contain;

          @media (max-width: 768px) {
            padding: 20px;
          }
        }

        .card-body {
          padding: 24px;
          .portfolio-card-subtitle {
            font-size: 14px;
            color: $redcarpet-700;
            text-transform: uppercase;
            margin-bottom: 12px;

            @media (max-width: 768px) {
              font-size: 12px;
            }
          }
          .portfolio-card-title {
            font-size: 22px;
            font-weight: bold;
            color: $black-400;

            @media (max-width: 768px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.search-results-content {
  margin-bottom: 64px;

  .col-lg-12 {
    .card {
      text-decoration: none;

      .card-body {
        padding: 24px;

        h2 {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 12px;

          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
        p {
          font-size: 14px;
          font-weight: 300;

          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }

      .news-card-body {
        text-decoration: none;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        h2 {
          font-size: 18px;
          font-weight: bold;

          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
        p {
          font-size: 14px;
          font-weight: 300;

          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
        span {
          font-size: 14px;
          font-weight: 400;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.pagination-container {
  hr {
    margin-bottom: 24px;
  }
  .slide-btn-container {
    margin-bottom: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-results-prev,
    .search-results-next {
      color: #000;
      padding: 8px 16px;
      border: 1px solid $white-300;
      border-radius: 4px;
      transition: 0.3s all ease;

      &:hover {
        background-color: $primary;
        color: $white-200;
      }
    }

    .search-results-page-info {
      width: auto;
      font-size: 14px;
      font-weight: 400;

      .current-slide {
        color: $redcarpet-700;
      }
    }
  }
}
