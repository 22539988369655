@use '../../custom' as *;

#separator{
    
    h1,.separator-page {
        font-size: small;
        font-weight: 400;
        color: $black-300;
        letter-spacing: 1px;  
        font-family: "nb_international_promono";
    }
    .separator-page {
        word-spacing: 10px;
    }

    .invert-separator {
        h1{
            color: $white-200;
        }
    }
}