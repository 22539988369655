@import "../../custom";

.scroll-update-container-wrapper {
  position: sticky;
  top: 0;
  height: 70px;
  margin: 0px 12px;
  display: none;
  flex-direction: column;
  z-index: calc($navbar-z-index - 1);
}

.scroll-update-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0px 3rem;
  background-color: #070707;
  border: inherit;
  border-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .gray-btn-2 {
    text-decoration: none;
    background-color: $white-300;
    padding: 10px;
    color: $black-400;
    border-radius: 2px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.5px;
    font-size: 12px;
    border: none;
    position: relative;
    transition: all 0.3s ease;

    .fa-arrow-right {
      position: absolute;
      opacity: 0;
      right: 0;
      transition: all 0.3s ease;
      color: $white-200;
    }

    &:hover {
      padding-right: 50px;
      background-color: $primary;
      color: $white-200;

      .fa-arrow-right {
        opacity: 1;
        transform: translate(-15px);
      }
    }
  }
}

.scroll-update-section {
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 2rem;

  a.scroll-update-item {
    background-color: transparent;
    padding: 3px 0px;
    border-radius: 0px;
    color: $white-400;
    font-family: "nb_international_prolight";

    &.active {
      color: #ffffff;
      background-color: transparent;
      border-bottom: 2px solid $redcarpet-600;
      font-family: "nb_international_proregular";
    }
  }
}

.scroll-progress-bar {
  display: flex;
  width: 100%;
  height: 5px;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .progress-indicator {
    background-color: $primary;
    width: 0px;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: width 0.1s ease-in-out;
  }
}

@media screen and (min-width: 768px) {
  .scroll-update-container-wrapper {
    display: flex;
  }
}
