@use '../../custom' as *;

#solutions-section {
    .solutions-body {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100vh;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        padding-top: 120px;
        margin-bottom: 100px;

        .solutions-video-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;

            &:after {
                content: ' ';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                background-blend-mode: multiply;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 26.5%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);
            }

            video {
                position: static;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &_image {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }

    .solutions-container {
        min-width: 65%;
        max-width: 65%;
        max-height: 100vh;
        /* Set a maximum height */
        overflow-y: auto;
        /* Enable vertical scrolling if content exceeds max height */
        overflow-x: hidden;

        @media (max-width: 1207px) {
            min-width: 90%;
            max-width: 90%;
        }

        /* Hide scrollbar for webkit browsers (Chrome, Safari) */
        ::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        /* For Firefox */
        scrollbar-width: none;
        /* Hide scrollbar in Firefox */

        /* For Internet Explorer and Edge */
        -ms-overflow-style: none;
        /* Hide scrollbar in IE and Edge */

        .accordion {
            background: transparent;
            margin: 96px 0 96px 0;

            &-item {
                background: transparent;
                color: $white-200;
                border: none;

                &.active-item {
                    background: rgba(141, 141, 141, 0.1);
                    backdrop-filter: blur(8px);
                    border-radius: 5px;
                }

            }

            @for $i from 1 through 10 {

                // Adjust the number (10) to the maximum expected number of accordion items
                &-item:nth-child(#{$i}) .accordion-button {
                    $opacity: 0.5 - ($i * 0.05); // Start with 0.9 opacity and decrease by 0.1 for each item
                    border-bottom: 1px solid rgba(255, 255, 255, $opacity);
                }
            }

            &-button {
                color: $white-200;
                cursor: pointer;
                background: transparent;
                box-shadow: none;
                padding: 25px 0;
                display: inline-flex;
                position: relative;
                overflow: hidden;
                align-items: center;
                justify-content: center;
                transition: all 0.4s ease;

                &-container {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                    transition: all 0.3s ease;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 99.7%;
                    height: 70%;
                    background: rgba(128, 128, 128, 0.11);
                    backdrop-filter: blur(15px);
                    transform: translate(-50%, -50%) scale(0.98);
                    transition: all 0.4s ease;
                    z-index: -1;
                    border-radius: 2px;
                    opacity: 0;

                    @media (max-width: 600px) {
                        transform: translate(-50%, -50%) scale(0.95);
                    }
                }


                &:hover {
                    .accordion-button-container {
                        margin: 0 25px;
                        padding: 0 10px;
                    }

                    border-bottom-color: transparent !important;
                }


                &:hover::before {
                    opacity: 1;
                }

                &::after {
                    display: none; // Hide the default icon
                }

                .fa-arrow-right {
                    color: $white-200;
                    font-size: small;
                }

                &:not(.collapsed) {

                    .accordion-button-container {
                        margin: 0 25px;
                        padding: 0 10px;
                    }

                    border-bottom-color: transparent !important;

                    &::before {
                        content: '';
                        opacity: 1;
                    }
                }
            }

            &-body {
                padding: 24px 48px 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                font-size: small !important;
                gap: 20px;

                &-image {
                    flex: 1;

                    img {

                        min-width: 100%;
                        height: 200px;
                        overflow: hidden;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 2px;
                    }
                }

                &-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    flex: 1;
                    height: 200px;
                    line-height: normal;

                    &_container {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        width: 100%;
                    }

                    &--title {
                        color: $white-400;
                        text-transform: uppercase;
                        font-size: inherit !important;
                        margin: 0 0 10px;
                    }

                    &_link {
                        text-decoration: none;
                        color: $white-200;
                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        min-width: 100%;
                        padding: 5px 0px;
                        gap: 5px;
                        transition: all 0.3s ease;

                        &--text {
                            margin: 0;
                            min-width: 100px;
                        }

                        .fa-arrow-right {
                            font-size: xx-small !important;
                        }

                        &--text,
                        &--icon {
                            font-size: inherit !important;
                        }

                        &:hover {
                            padding: 5px 15px;
                            background-color: $white-300;
                            color: $black-400;
                            border-radius: 2px;
                        }
                    }

                    &_btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-transform: uppercase;
                        font-size: inherit !important;
                        padding: 15px 40px;
                        border: none;
                        min-width: 100%;
                        height: auto;
                        border-radius: 2px;
                        transition: all 0.3s ease;
                        position: relative;
                        background-color: $white-300;
                        color: $black-400;
                        text-decoration: none;
                        cursor: pointer;

                        .fa-arrow-right {
                            position: absolute;
                            right: 0;
                            transform: translateX(-10px);
                            opacity: 0;
                            transition: all 0.3s ease;
                            color: $white-200;
                        }

                        &:hover {
                            padding-right: 70px;
                            padding-left: 0px;
                            background-color: $black-400;
                            color: $white-200;

                            .fa-arrow-right {
                                opacity: 1;
                                transform: translate(-30px);
                            }
                        }
                    }
                }

                @media (max-width: 880px) {
                    flex-direction: column;
                    align-self: stretch;
                    padding: 24px 12px;
                    gap: 48px;

                    &-image {
                        width: 100%;

                        img {
                            height: 138px;
                        }
                    }

                    &-info {
                        justify-content: space-evenly;
                        width: 100%;

                        &_container {
                            margin-bottom: 12px;
                        }
                    }
                }
            }
        }

        .solutions-typography {

            text-align: center;
            margin: 120px 0 48px 0;
            line-height: 1rem;

            h2 {
                color: $white-200;
                font-weight: 300;
                font-size: 36px;
            }

            @media (max-width: 768px) {
                h2 {
                    font-size: 24px;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    #solutions-section {
        padding: min(1.5vw, 25px);
        border-radius: 5px;
    }
}