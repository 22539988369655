@import "../custom";

$page-header-fc: #f4f4f4;
$page-header-overlay-z-index: 1;

.page-header-with-image-container {
    width: 100%;
    margin-top: $navbar-height-mv;
}

.page-header-with-image-wrapper {
    position: relative;
    width: 100%;
    height: 70vh;
    min-height: 300px;
    max-height: 700px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

}

.header-bg-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ($page-header-overlay-z-index - 2);

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.header-bg-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: $page-header-overlay-z-index;
}

.page-header-content {
    h1 {
        color: $page-header-fc;
        font-size: 27px;
        font-family: "nb_international_proregular";
        text-align: center;
    }
}

@media screen and (min-width: 640px) {
    .page-header-with-image-wrapper {
        min-height: 400px;
        height: 25vh;
    }

    .page-header-content h1 {
        font-size: 32px;
    }
}

@media screen and (min-width: 768px) {
    .page-header-content h1 {
        font-size: 36px;
    }
}

@media screen and (min-width: 1024px) {
    .page-header-with-image-container {
        padding: 12px;
        margin-top: $navbar-height;
    }

    .page-header-with-image-wrapper {
        border-radius: 3px;
    }

    .page-header-content h1 {
        font-size: 41px;
    }
}

@media screen and (min-width: 1280px) {
    .page-header-content h1 {
        font-size: 47px;
    }
}