@import "@../../../../custom";

.portfolio-solutions-implemented-container {
    .right-section {
        .our-capabalities-tech-heading {
            padding-top: 2rem;
            padding-bottom: 3rem;
            h2 {
                font-size: 18px;
                color: $cooldecember-100;
                font-weight: 400;
            }
        }
        .capabilities-content-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            min-height: 100px;
            row-gap: 1rem;
            padding-bottom: 1.5rem;
            border-bottom: 1px solid $white-300;
            margin-bottom: 1.25rem;
        }

        .capabilities-content-icon {
            display: flex;
            justify-content: start;
            align-items: center;
            width: 100%;

            img.icon {
                width: 15px;
            }
        }

        .capabilities-content-desc {
            color: #fefdfc;
            font-size: 16px;
        }

        .cap-tech-content-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            border-radius: 4px;
            overflow: hidden;

            .cap-tech-cont-image-wrapper {
                height: 200px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .cap-tech-cont-desc {
                background-color: #fefdfc;
                padding: 1.5rem;
                min-height: 100px;

                .desc-heading{
                    font-size: 22px;
                    font-family: 'nb_international_proregular';
                }

                .desc-content {
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (min-width: 576px) {
    .right-section {
        .capabilities-content-desc {
            font-size: 18px;
        }
    }
}

@media screen and (min-width: 768px) {
    .portfolio-solutions-implemented-container {
        .right-section {
            .capabilities-content-container {
                margin-right: 1.25rem;
            }

            .cap-tech-content-container {
                margin-right: 1.25rem;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .right-section {
        .capabilities-content-desc {
            font-size: 20px;
        }

        .capabilities-content-icon {
            img.icon {
                width: 20px;
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .right-section {
        .capabilities-content-desc {
            font-size: 22px;
        }
    }
}