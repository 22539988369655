.connect-form {
  position: relative;
  .form-wrapper {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 120px;
    margin-top: -80px;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    
  }

  .form-row {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    padding-bottom: 5px;

    .input-container {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .phone-section-container {
    display: flex;
    gap: 20px;
    align-items: center;
    padding-bottom: 5px;
    

    .input-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 333px;
    height: 59px;
    }
  }
 

  .form-control {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    height: 59px; /* This applies to all input fields */
  }

  

  textarea.form-control {
    resize: none;
    height: 160px; /* Specific height for the textarea */
  }
  
  @media (max-width: 768px) {
    .form-row,
    .phone-section-container {
      flex-direction: column; /* Stack inputs vertically on smaller screens */
    }

    .form-row .input-container,
    .phone-section-container .input-container {
      width: 100%; /* Ensure full width for each input */
    }

    .form-control {
      width: 100%; /* Full width for all inputs on mobile */
    }

    .form-wrapper {
      margin-top: 40px; /* Adjust top margin for mobile */
    }
  }
}
