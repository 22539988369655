@import "../../custom";

$page-header-fs: 28px;
$page-header-overlay-z-index: 1;

.portfolio-sustainability-wrapper {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 70vh;
  min-height: 500px;
  max-height: 700px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 306px 197px 102px 0px rgba(169, 168, 167, 0.01), 196px 126px 93px 0px rgba(169, 168, 167, 0.06), 110px 71px 79px 0px rgba(169, 168, 167, 0.20), 49px 32px 58px 0px rgba(169, 168, 167, 0.34), 12px 8px 32px 0px rgba(169, 168, 167, 0.39);
}

.portfolio-sustainability-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
    
  }
}

.portfolio-sustainability-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  padding: max(1vw, 10px);

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    row-gap: 20px;
    color: $white-200;
  }

  h2 {
    font-family: "nb_international_proregular";
    font-size: 24px;
  }
  p {
    font-family: "nb_international_prolight";
    font-size: 18px;
    max-width: 80%;
  }
}
  
  
  @media screen and (min-width: 640px) {
    .portfolio-sustainability-content {
      h2 {
        font-size: 36px;
      }
  
      p {
        font-size: 24px;
        max-width: 70%;
      }
    }
  }
  
