@use "../../custom" as *;

.products-list-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;


  .tab-container {
    width: 100%;
    position: relative;

    .nav-tabs {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: $white-200;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;

      @media (max-width: 768px) {
        position: sticky;
        top: $navbar-height-mv;
        z-index: 5;
        transition: 0.2s opacity ease;

        &.sticky-fixed {
          opacity: 0;
        }
      }

      .nav-item {
        .nav-link {
          color: $black-200;
          padding: 12px 24px;
          font-family: "nb_international_prolight";
          border: none;
          background-color: transparent;
          width: 100%;
          font-size: 16px;

          &.active {
            color: $redcarpet-900;
            border-bottom: 3px solid $redcarpet-900;
            font-weight: bold;
          }
        }
      }
    }
  }

  .tab-content {
    padding: 24px 0;

    .tab-pane {
      background-color: transparent;

      .wrapper {
        .product-item {
          text-decoration: none !important;
          position: relative;

          .card {
            border-radius: 3px;
            transition: all 0.3s ease;
            user-select: none;
            width: 100%;
            height: 380px;
            text-decoration: none;

            &-img-top {
              border-radius: 3px 3px 0 0;
              height: 200px;
              object-fit: cover;
            }

            &-body {
              padding: 24px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: start;
            }

            &-title {
              color: $redcarpet-900;
              font-size: 10px;
              text-transform: uppercase;
              margin: 0;
              margin-bottom: 12px;
              font-family: "nb_international_promono";

            }

            &-text {
              font-size: 18px;
              margin-bottom: 20px;
            }

            &-btn {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &:hover {
              box-shadow: 121px 146px 53px 0px rgba(0, 0, 0, 0),
                77px 93px 49px 0px rgba(0, 0, 0, 0.01),
                44px 53px 41px 0px rgba(0, 0, 0, 0.05),
                19px 23px 30px 0px rgba(0, 0, 0, 0.09),
                5px 6px 17px 0px rgba(0, 0, 0, 0.1);
            }
          }

          .card-btn-wrapper {
            width: 100%;
            position: absolute;
            bottom: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            a {
              width: 80%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    margin-top: 64px;

    .nav-tabs {
      justify-content: center;

      .nav-item {
        flex: 1;

        .nav-link {
          font-size: 14px !important;
          padding: 12px 20px;
        }
      }
    }

    .swiper-product {
      .product-item {
        .card {
          &-title {
            font-size: 12px !important;
          }

          &-text {
            font-size: 16px !important;
          }
        }
      }
    }
  }
}