//Red Carpet Colors
$redcarpet-100: #f9f2f2;
$redcarpet-200: #f2e5e5;
$redcarpet-300: #e5c7c7;
$redcarpet-400: #d7a3a5;  
$redcarpet-500: #c87678;
$redcarpet-600: #b8202a;
$redcarpet-700: #a51d26;
$redcarpet-800: #8f1921;
$redcarpet-900: #74141b; 
$redcarpet-1000: #520e13;  

//Cool December Colors
$cooldecember-0: #fffffe;
$cooldecember-100: #fefdfc;
$cooldecember-200: #fdfbf8;
$cooldecember-300: #e2e1de;
$cooldecember-400: #a09f9d;

//Thamar Black Colors
$thamarblack-0: #f2f2f2;
$thamarblack-100: #dddddd;
$thamarblack-200: #b5b5b5;
$thamarblack-300: #818181;
$thamarblack-400: #191818;

$primary: $redcarpet-600;

$black-0 : $thamarblack-0;
$black-100: $thamarblack-100;
$black-200: $thamarblack-200;
$black-400: $thamarblack-400;
$black-300: $thamarblack-300;

$white-200: $cooldecember-200;
$white-300: #D9D9D9;
$white-400: $cooldecember-400;



// Variables for the Navigation Bar

$navbar-height: 105px; // Height of the navigation bar.
$navbar-height-mv: 65px; // Height of the navigation bar in mobile view.
$navbar-z-index: 10; // Z-index of the navbar container to control stacking order.
$nav-menu-item-fs: 12px; // Font size for the navigation menu items.
$nav-menu-action-fs: 16px; // Font size for the action items in the navigation menu.
//$nav-menu-item-fs-mv: 14px; // Font size for the navigation menu items in mobile view.

// Variables for the Scroll Updater

$scroll-update-container-height: 70px; // Height of the scroll update container.

.mt-120px {
    margin-top: 120px
}

.my-24px {
    margin-top: 24px;
    margin-bottom: 24px;
}