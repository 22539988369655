@use "../../custom" as *;

.solutions-details-section {

    .sol-content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 64px;

    }

    .solutions-details-slider {
        .swiper-wrapper {
            position: relative;
        }
    
        .swiper-wrapper::after {
            content: '';
            position: absolute;
            border-bottom: 1px dashed $white-400;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            width: 500%;
    
        }
    }

    

    .details-slider--item {
        padding: 48px 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: 100%;
    }

    .details-slider--heading {
        color: $primary;
    }

    .details-slider--title {
        font-size: 27px;
        margin-bottom: 24px;
        font-family: "nb_international_proregular";
    }

    .details-slider--desc {
        color: $black-300;
        font-size: 18px;
        line-height: 26px;
    }



    .slide-btn-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 48px;
        margin-top: 24px;


        .sol-details-prev,
        .sol-details-next {
            padding: 8px 16px;
            border: 1px solid $white-300;
            border-radius: 4px;
            transition: 0.3s all ease;

            &:hover {
                background-color: $primary;
                color: $white-200;
            }
        }

        .swiper-button-disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .our-process {
        &-title {
            margin-bottom: 60px;
            text-align: center;
            font-size: 36px;

            h5 {
                font-size: inherit;
                font-family: 'nb_international_proregular';
            }
        }
    }

    .map-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        text-align: center;

        &--title {
            font-size: 36px;
            font-family: 'nb_international_proregular';
        }

        &--desc {
            color: $black-300;
            font-size: 22px;
            line-height: 30px;
            max-width: 60%;
        }
    }



    .map-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .map-img-wrapper {
            width: 924px;
            height: 761px;
            position: relative;

            .dotted-map-img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    .map-swiper {
        width: 100%;
    }


    .logo-wrapper {
        height: 80px;
        width: 112px;
        position: absolute;
        padding: 2px;
        background-color: $white-200;
        border: 1px solid $white-300;
        border-radius: 4px;
        transition: all 0.3s ease;
        cursor: pointer;

        &.active {
            outline: 1px solid $primary;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

        &:hover {
            box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
        }

        &.no-hover:hover {
            box-shadow: none;
        }
    }

    .logo-card {
        background-color: $white-200;
        width: 313px;
        height: 380px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 284px 288px 113px 0px rgba(0, 0, 0, 0.00), 182px 184px 103px 0px rgba(0, 0, 0, 0.01), 102px 104px 87px 0px rgba(0, 0, 0, 0.05), 45px 46px 65px 0px rgba(0, 0, 0, 0.08), 11px 12px 36px 0px rgba(0, 0, 0, 0.09);
        border: 1px solid $white-300;
        border-radius: 4px;
        position: absolute;
        right: 19rem;
        bottom: 13.6rem;
        gap: 24px;
        opacity: 0;
        transition: opacity 0.3s ease;
        pointer-events: none;

        &.active {
            opacity: 1;
            pointer-events: initial;
            cursor: initial;
        }
    }

    .map-solutions-list {
        list-style: none;
    }

    .card-image {
        width: 100%;
        height: 76px;

        .top-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    .card-content {
        border-top: 1px solid $white-300;
        padding-top: 24px;
        width: 100%;
        height: 100%;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .project-title {
            font-family: 'nb_international_proregular';
        }

        .card-list {
            ul {
                list-style: none;
                padding: 0;
                color: $black-300;
                margin: 18px 0 0 0;
            }

            li {
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0px;
                }
            }

        }

        .distance-icon {
            width: 16px;
        }
    }

    .related-projects {
        margin-bottom: 64px;
    }


    @media (max-width: 768px) {

        .related-projects {
            margin-bottom: 40px;
        }

        .logo-card {
            display: block;
            position: initial;
            width: 100%;
            height: 100%;
            min-height: 342px;
            font-size: 14px;
            opacity: 1;
            pointer-events: initial;
        }

        .logo-wrapper {
            height: 50px;
            width: 60px;
         }

        .map-content {
            .map-img-wrapper {
                height: 292px;
                width: 100%;
                position: relative;
            }
        }

        .card-image {
            height: 57px;
            margin-bottom: 15px;
        }

        .mobile-geo-icon-1,
        .mobile-geo-icon-2,
        .mobile-geo-icon-3 {

            display: initial;

        }

        .mobile-geo-icon-1 {
            top: 0;
            left: 8rem;
        }

        .mobile-geo-icon-2 {
            top: 5rem;
            left: 6rem;
        }

        .mobile-geo-icon-3 {
            top: 3.5rem;
            right: 5rem;
        }

        .our-process {
            &-title {
                font-size: 24px;
                margin-bottom: 48px;
            }
        }

        .map-header {
            &--desc {
                max-width: 100%;
                font-size: 18px;
                line-height: 24px;
            }

            &--title {
                font-size: 24px;
            }
        }

        .sol-content-wrapper {
            gap: 40px;
        }

    }

    

   

    @media (max-width: 576px) {
        .details-slider--title {
            font-size: 18px;
        }

        .details-slider--item {
            gap: 12px;
        }

        .details-slider--desc {
            font-size: 16px;
            line-height: 22px;
        }

        .slide-btn-container {
            display: none;
        }
    }

}

.sol-details-our-process {
    margin-top: 120px;
    margin-bottom: 120px;
    overflow-x: hidden;
}

@media (max-width: 768px) {
    .sol-details-our-process {
        margin-top: 64px;
        margin-bottom: 128px;
    }
}

#solution_details_overview {
    scroll-margin-top: 150px;
}

#solutions_our_process {
    scroll-margin-top: calc($scroll-update-container-height + 30px);
}

#componentRelatedProjects {
    scroll-margin-top: calc($scroll-update-container-height + 30px);
    padding-bottom: 120px;
    overflow-x: hidden;
}