.map {
    margin-bottom: 120px;
 
 .mhead{
    margin-bottom: 64px;
 }

 }
 
 iframe {
    
    height: 500px; 
    border: 0; 
    align-items: center;
 }
 
 .mapdetail {
     margin-top: 60px;
     margin-left: 20px;
    
 }
 .mapdetail .col-12 {
   margin-top: 0px;
   margin-bottom: 48px;
}
 