@use "../../custom" as *;

//Bounce and elastic easing animation
.home-spotlight-section#home_spotlight_container {
  margin-top: $navbar-height;

  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
  }
}

.home-spotlight-slider {
  display: flex;
  position: relative;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
  padding: 0;

  .home-spotlight-slide {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    .home-spotlight-slide-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .home-spotlight-video-container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;

      &:after {
        content: ' ';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
      }

      video {
        position: static;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.home-spotlight-slide-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 115px;
  height: 112px;
}

@media screen and (min-width: 576px) {
  .home-spotlight-section#home_spotlight_container {

    h2 {
      font-size: 28px;
      line-height: 34px;
    }
  }
}

@media screen and (min-width: 640px) {
  .home-spotlight-slide-logo {
    width: 154px;
    height: 150px;
  }
}

@media screen and (min-width: 768px) {
  .home-spotlight-slider {
    height: 100vh;
  }

  .home-spotlight-slide-logo {
    width: 184px;
    height: 179px;
  }

  .home-spotlight-section#home_spotlight_container {

    h2 {
      font-size: 32px;
      line-height: 36px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .home-spotlight-slide-logo {
    width: 246px;
    height: 240px;
  }

  .home-spotlight-slider {
    padding: min(1.5vw, 25px);
  }

  .home-spotlight-section#home_spotlight_container {

    h2 {
      font-size: 36px;
      line-height: 40px;
    }
  }
}