@use "../../custom" as *;

.products-cards-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 120px 0;

  .related-products-title {
    margin-bottom: 64px;
    font-size: 36px;

    @media (max-width: 768px){
      margin-bottom: 40px;
    }
    h1 {
      font-size: inherit;
    }
  }

  .swiper-product {
    width: 100%;
    padding-bottom: 70px;

    @media (max-width: 768px){
      padding-bottom: 40px;
      padding-left: 16px !important;
      padding-right: 16px !important;
    }

    .swiper-slide {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease;
    }

    .swiper-slide-fully-visible,
    .swiper-slide-visible {
      opacity: 1;
      pointer-events: auto;
    }

    .product-item {
      text-decoration: none !important;
      position: relative;

      .card {
        border-radius: 3px;
        transition: all 0.3s ease;
        user-select: none;
        height: 380px;
        text-decoration: none;

        &-img-top {
          border-radius: 3px 3px 0 0;
          height: 200px;
          object-fit: cover;
        }

        &-body {
          padding: 24px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: start;
        }

        &-title {
          color: $redcarpet-900;
          font-size: 10px;
          text-transform: uppercase;
          margin: 0;
          margin-bottom: 12px;
          font-family: "nb_international_promono";
        }

        &-text {
          font-size: 18px;
          margin-bottom: 20px;
        }

        &-btn {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:hover {
          box-shadow: 121px 146px 53px 0px rgba(0, 0, 0, 0),
            77px 93px 49px 0px rgba(0, 0, 0, 0.01),
            44px 53px 41px 0px rgba(0, 0, 0, 0.05),
            19px 23px 30px 0px rgba(0, 0, 0, 0.09),
            5px 6px 17px 0px rgba(0, 0, 0, 0.1);
        }
      }

      .card-btn-wrapper {
        width: 100%;
        position: absolute;
        bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          width: 80%;
        }
      }
      
    }
  }

  @media (max-width: 576px) {
    .related-products-title {
      font-size: 24px; // Example media query adjustment, if needed
    }

    .swiper-product {
      .product-item {
        .card {
          &-title {
            font-size: 12px;
          }

          &-text {
            font-size: 16px;
          }
        }
      }
    }
  }
}
