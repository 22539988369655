@use "../../custom" as *;

#news-insights-listing-section {
  margin-top: 50px;

  .news-insights-container {
    padding-left: 16px;
    padding-right: 16px;
    @media (min-width: 1200px) {
      max-width: 1164px;
      padding-left: 0;
      padding-right: 0;
    }

    @media (max-width: 576px) {
      margin-top: 64px;
    }
  }

  #filterDropdown:after {
    display: none;
  }

  .custom-tab-container {
    gap: 24px;
    .nav {
      width: 100%;
    }

    .dropdown {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: 992px) {
        width: 255px;
      }
      .btn {
        &::after {
          display: none;
        }
      }

      .dropdown-toggle {
        color: $black-300;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 12px 24px;
        justify-content: space-between;
        @media (min-width: 992px) {
          gap: 12px;
        }

        i {
          font-size: 12px;
          color: #000;
          transform: translateY(1px);
          transition: 0.2s transform ease;
        }

        &.show {
          i {
            transform: rotate(180deg);
          }
        }

        .fw-bold {
          color: $black-400;
          margin-left: 12px;
        }

        .dropdown-icon {
          max-width: fit-content;
        }
      }

      .dropdown-menu {
        width: 100%;
      }
    }
  }

  .nav-link {
    color: $black-200;
    padding: 12px 24px;
    font-family: "nb_international_prolight";
    border: none;
    background-color: transparent;

    &.active {
      color: $redcarpet-900;
      border-bottom: 3px solid $redcarpet-900;
      font-family: "nb_international_probold";
    }
  }

  .news-insights-tab-content {
    a {
      text-decoration: none;
      h5,
      p {
        color: #191818;
      }
    }

    width: 100%;
    margin-left: 0;
    margin-right: 0;

    .wrapper {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;

      .col-md-6 {
        margin-top: 24px;
        padding-left: 10px;
        padding-right: 10px;

        @media (min-width: 768px) {
          margin-top: 20px;
        }
      }
    }

    .tab-pane {
      &.active {
        display: flex;
        align-items: center;
      }
    }
    .news-insight-item {
      position: relative;
      border: 1px solid $white-300;
      border-radius: 4px;
      gap: 24px;
      transition: margin-bottom 0.3s ease, background-color 0.3s ease;

      @media (max-width: 768px) {
        gap: 0px;
      }

      @media (min-width: 768px) {
        padding: 24px;
      }

      @media (min-width: 992px) {
        height: 302px;
      }

      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        @media (max-width: 768px) {
          padding: 12px;
        }

        @media (min-width: 992px) {
          width: 50%;
        }

        .news-insight-item-image {
          width: 100%;
          height: 254px;
          object-fit: cover;
          transition: transform 0.3s ease;

          @media (max-width: 768px) {
            height: 247px;
          }
        }


      }

      .news-content {
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 768px) {
          padding: 20px;
          height: auto;
        }

        @media (min-width: 992px) {
          width: 50%;
        }

        .news-info-text {
          gap: 24px;

          @media (max-width: 768px) {
            gap: 12px;
          }
        }

        .news-info-text h5 {
          font-size: 18px;
          margin-bottom: 10px;
          font-weight: 800;
        }

        .news-info-text p {
          margin-bottom: 0;
          font-weight: 300;
          font-size: 14px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .news-info-button {
          margin-top: 12px;
          font-size: 14px;
          font-weight: 400;

          span {
            color: $black-400;
            transition: color 0.3s ease;

            .fa-arrow-right {
              opacity: 0;
              color: $white-200;
              background-color: $primary;
              padding: 10px;
              border-radius: 2px;
              transition: opacity 0.3s ease;
              margin-left: 8px;
            }
            &:hover {
              color: $redcarpet-700;
              .fa-arrow-right {
                opacity: 1;
              }
            }
          }
        }
      }

      .news-info-details {
        font-family: "nb_international_promono";
        position: absolute;
        padding: 0px 12px;
        bottom: -50px;
        left: 0;
        right: 0;
        text-align: center;
        opacity: 0;
        transition: opacity 0.3s ease, bottom 0.3s ease;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1px;
        pointer-events: none;

        p {
          color: $redcarpet-700;
          text-transform: uppercase;
          margin: 0;
        }

        span {
          display: block;
          color: $black-300;
        }
        @media (max-width: 768px) {
          opacity: 1;
          bottom: -32px;
        }
      }

      &:hover {
        margin-bottom: 20px;
        background-color: $cooldecember-300;
        .news-info-details {
          opacity: 1;
          bottom: -30px;
        }
      }
      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }
  }

  .text-center {
    margin-bottom: 150px;
    @media (max-width: 767px) {
      margin-bottom: 50px;
    }
  }
}
