@use "../../custom" as *;

#connect-section {
  overflow: hidden;
  
  .form-margin-120px {
    margin-top: 120px;
  }
  .connect-content-wrapper {
    position: relative;
    min-height: 380px;
    transition: min-height 0.5s ease-in-out;

    @media (max-width: 767.98px) {
      min-height: 440px;
    }
  }

  .connect--title {
    margin: 96px 0 48px 0;
  }

  .connect-content-wrapper.form-expanded {
    min-height: 800px; // Expands to fit the form's height
  }

  .animate-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  #connect-header-container {
    z-index: 1;
    transform: translateX(0); // Initial state (visible)
  }



  #connect-header-container.heading-active {
    transform: translateX(-100%); // Slide out to the left
  }

}



#partner-slider {
  .connect-slider {
    overflow: hidden;
    position: relative;

    .slide-track {
      -webkit-animation: scroll-reverse 60s linear infinite;
      animation: scroll-reverse 60s linear infinite;
      display: inline-flex;
      white-space: nowrap;
      gap: 20px;

      .slide {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
        width: 300px;
        border-radius: 10px;
        margin: 0px 10px;

        @media (max-width: 768px) {
          height: 200px;
          width: 200px;
        }

        img {
          max-width: 80%;
          max-height: 80%;
          object-fit: contain;
        }
      }
    }
  }

  @keyframes scroll-reverse {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(calc(-100% + (100vw)));
    }

    100% {
      transform: translateX(0);
    }
  }

  @-webkit-keyframes scroll-reverse {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(calc(-100% + (100vw)));
    }

    100% {
      transform: translateX(0);
    }
  }
}
