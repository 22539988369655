@use "./custom" as *;

html[dir="rtl"] {
  // Navbar, Mega Menu, and Hamburger

  .navbar-logo {
    left: auto;
    right: 35px;

    @media screen and (max-width: 1023px) {
      right: 25px;
    }
  }

  .navbar-actions {
    left: 35px;
    right: auto;

    .search-action {
      .live-search-results-container {
        left: auto;
        right: 0;
        transform: translateX(80%);

        .see-all-res-button-container {
          img {
            transform: rotate(180deg);
          }
        }
      }

      .nav_search_action_icon {
        left: 0;
        right: auto;
      }

      .nav-search-form-input {
        left: 0px;
        right: auto;
        padding-right: 5px;
        padding-left: 25px;
        transform: translateY(-50%) translateX(-8px);
      }

      .search-form-button-with-icon {
        right: auto;
        left: 0px;
      }
    }

    .language-change {
      .language-change-menu {
        right: auto;
        left: -30px;

        &::before {
          right: auto;
          left: 28px;
        }
      }
    }
  }

  .navbar-item-solutions-expand {
    left: auto;
    right: 0;

    .solutions-expand-container {
      .solutions-container-img {
        padding-left: 1rem;
        padding-right: min(1vw, 35px);
      }

      .solutions-container-data {
        .data-footer {
          a#footer_inquiry_button {
            .inquiry-button {
              &:hover {
                span {
                  transform: translateX(30%);
                }
              }

              img,
              i {
                left: 12px;
                right: auto;
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }

  .hamburger-container {
    right: auto;
    left: -100%;
    transition: left 0.3s ease-in-out;
  }

  .ham-language-menu-container {
    right: auto;
    left: -100%;
    transition: left 0.3s ease-in-out;
  }

  .ham-search-menu-container {
    right: auto;
    left: -100%;
    transition: left 0.3s ease-in-out;

    .search-menu-header {
      justify-content: start;
    }

    .ham-search-action-form {
      .ham-search-form-input {
        padding-left: 30px;
        padding-right: 10px;
      }

      .search-form-button-with-icon {
        right: auto;
        left: 10px;
      }
    }

    .live-search-results-container {
      left: auto;
      right: 0;
    }
  }

  .hamburger-data {
    .hamburger-learnmore-section {
      h5 {
        margin-left: 0;
        margin-right: 1rem;
      }
    }

    .hamburger-nav-link {
      img {
        transform: rotate(180deg);
      }
    }
  }

  .hamburger-contact-section {
    .phone-number {
      img {
        transform: rotate(-90deg);
      }
    }
  }

  .hamburger-container.toggle-hamburger {
    left: 0;
    right: auto;
  }

  .ham-language-menu-container.toggle-ham-language-menu {
    left: 0;
    right: auto;
  }

  .ham-search-menu-container.toggle-ham-search-menu {
    left: 0;
    right: auto;
  }

  //FOOTER

  #footer-2 {
    .footer {
      &-grid-container {
        img {
          margin-left: 150px;
          margin-right: 0;
        }
      }

      @media (max-width: 1200px) {
        &-grid-container {
          img {
            margin-left: 0;
          }
        }
      }
    }
  }

  //Home

  #btn-primary {
    .button-container {
      &-btn {
        &_icon {
          i {
            transform: rotate(180deg);
          }
        }
        &:hover {
          .button-container-btn_text {
            transform: translateX(3px);
          }

          .button-container-btn_icon {
            transform: translateX(-3px);
          }
        }
      }
    }
  }
  //Statistics Section

  #statistics-container {
    .content {
      .content-row {
        direction: ltr;
      }
    }
  }
  //Solutions Section

  #solutions-section {
    .solutions-container {
      .accordion-button {
        .fa-arrow-right {
          transform: rotate(180deg);
          margin-left: 0 !important;
        }
      }
      .accordion-body-info_link {
        .fa-arrow-right {
          transform: rotate(180deg);
        }
      }
      .accordion-body-info_btn {
        .fa-arrow-right {
          left: 0;
          right: auto;
          transform: rotate(180deg);
        }
        &:hover {
          padding-left: 70px;
          padding-right: 0px;

          .fa-arrow-right {
            transform: rotate(180deg) translateX(-30px);
          }
        }
      }
    }
  }

  //Products Section

  .gray-btn-1 {
    .fa-arrow-right {
      left: 0;
      right: auto;
      transform: rotate(180deg);
    }

    &:hover {
      padding-left: 50px;
      padding-right: 0;

      .fa-arrow-right {
        opacity: 1;
        transform: rotate(180deg) translate(-15px);
      }
    }
  }
}
