@use "../../custom" as *;

.breadcrumbs-container {
  padding-top: $navbar-height;
}
.news-details-top-section {
  @media (min-width: 992px) {
    max-width: 926px !important;
  }
  .insight-header {
    margin-bottom: 64px;
    @media (max-width: 768px) {
      margin-bottom: 56px;
    }

    .insight-title {
      color: $black-400;
      font-size: 36px;
      max-width: 70%;
      margin-bottom: 24px;
      @media (max-width: 768px) {
        font-size: 24px;
        max-width: 100%;
        margin-bottom: 12px;
      }
    }

    .insight-meta {
      display: flex;
      flex-wrap: wrap;

      .meta-item {
        font-size: 14px;
        color: $black-400;
        letter-spacing: 1px;

        .meta-logo {
          color: $redcarpet-700;
          margin-right: 5px;
        }
      }
    }
  }

  .insight-details {
    margin-top: 48px;
    margin-bottom: 48px;
    @media (max-width: 768px) {
      margin-top: 24px;
      margin-bottom: 24px;
    }
    h1 {
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .img-fluid {
    width: 100%;
    height: 512px;
    object-fit: cover;
    @media (max-width: 768px) {
      height: 192px;
    }
  }
}

.news-details-bottom-section {
  @media (min-width: 992px) {
    max-width: 926px !important;
  }
  .img-fluid {
    width: 100%;
    height: 100%;
  }
  .row {
    margin-bottom: 64px;
    @media (max-width: 768px) {
      margin-bottom: 56px;
    }
  }
}

.icon-section {
  hr {
    margin-bottom: 24px;
    @media (max-width: 768px) {
      margin-bottom: 56px;
    }
  }

  @media (min-width: 992px) {
    max-width: 926px !important;
  }
  span {
    margin-right: 4px;
  }
}
