@use "../../custom" as *;

.career-section {
    .img-fluid {
        width: 572px;
        height: 390px;
    }
    

    .container {
        max-width: 1200px;
        margin: auto;
    }

    .pb-120px {
        padding-bottom: 120px;
    }

    .font-size-36px {
        font-size: 36px;
    }

    .font-size-22px {
        width: 528px;
        font-size: 22px;
    }

    .default-gray {
        color: #6c757d;
        /* Example gray color */
    }

    .lcareer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        text-transform: uppercase;
        margin-top: 15px;
        gap: 10px; /* Adjust the spacing between list items */
        margin-top: -15px;
    }
    
    .lcareer li {
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 0;
        font-family: "nb_international_promono";
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative; /* Add positioning */
        margin-left: 10px;
    }
    
   
  
    
    
    .mhead {
        letter-spacing: -0.75px;
        line-height: 43.2px;
        font-size: 36px;
        margin-top: 100px;
    }

    .shead {
        color: #818181;
        font-size: 22px;
        letter-spacing: 0px;
        line-height: 30px;
        margin-bottom: 20px;
    }

    .custom-card {
        height: 100px;
        min-height: 220px;
        border: 1px solid rgba(217, 217, 217, 1);
        width: 100%;
        background-color: $white-200;
    }

    .custom-card-subtitle {
        letter-spacing: 2px;
        font-size: 14px;
        color: var(--default-red1);
    }

    .card-title {
        font-size: 22px;
        font-weight: bold;
        color: var(--default-black);
        letter-spacing: 0;
        line-height: 30px;
        text-align: left;
        padding: 12px;
     
        
        
    }

   

    .custom-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 40px;
        
    }


    .bcrum ul {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: -30px;
    }
   

    .bcrum ul li a {
        color: gray;
        text-decoration: none;


    }
   

    .bcrum ul li a.active {
        color: #74141B;
        text-decoration: none;

    }

    .shead {
        color: var(--default-gray);
        font-size: 22px;
        letter-spacing: 0px;
        line-height: 30px;
    }

    .image-bg-section-wrapper {
        position: relative;
        width: 100%;
        min-height: 400px;
        height: auto;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 120px;
    }

    .bg-image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
       
    }

    .bg-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* Cover the entire area */
        position: absolute;
        top: 0;
        left: 0;
    }
    .benefitcard-body{
        padding: 24px;
    }

    .bg-image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 400px;
        background-color: rgba(0, 0, 0, 0.4);
        /* Transparent overlay */
        z-index: 1;
        /* Position above image but below content */
    }

    .bg-overlay-content {
        color: white;
        text-align: center;
    }


    .solution-card {
        border: 1px solid rgba(217, 217, 217, 1);
        transition: box-shadow 0.3s ease, transform 0.3s ease;
        width: 374.67px;
        height: 376px;
        

        &:hover {
            box-shadow: 121px 146px 53px 0px rgba(0, 0, 0, 0.00), 77px 93px 49px 0px rgba(0, 0, 0, 0.01), 44px 53px 41px 0px rgba(0, 0, 0, 0.05), 19px 23px 30px 0px rgba(0, 0, 0, 0.09), 5px 6px 17px 0px rgba(0, 0, 0, 0.10);
        }

    }
    
    .card.solution-card {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .card-lorem {
        color: #818181;
        text-align: left;
        p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: -110px;
        }
        
    }
    

    
    .card-img-top {
        height: 200px;
        width: 374.67px;
        width: 1;
        object-fit: cover;
    }
    .abox{
        padding-bottom: 70px;
      
    }
    .custom-card-body {
    
        display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure content stays between top and bottom */
    height: 100%; /* Take up full height of the card */
    
    }
    
    .custom-btn {
     margin-top: auto; /* Push the button to the bottom */
    width: 100%; /* Ensure it takes full width in the container */
    max-width: 100%; /* Add a max-width to avoid overflow */
    padding: 10px 0; /* Adjust padding for better sizing */
   align-items: center;
   margin-bottom: 8px;
   
    }
    

    @media (max-width: 768px) {
        .career-section .row {
            justify-content: center;
        }
    
        .col-md-6 {
            display: flex;
            justify-content: center;
        }
    
        .custom-card {
            width: 100%; /* Ensure the card takes full width */
           
        }
        .container {
            text-align: center;
        }


        .img-fluid {
            max-width: 100%;
            height: auto;
        }

        .mhead {
            font-size: 26px;
            line-height: 31.2px;

        }

        .shead {
            margin-top: 25px;
            font-size: 18px;
        }

        .container.mt-5.pb-120px {
            margin-top: -20px;
            /* Adjust the negative margin-top value to move the section up */
        }

        .career img {
            margin-bottom: 40px;
            /* Adjust the value as needed */
        }

        h1.font-size-36px {
            margin-top: 20px;
            /* Adjust the value as needed */
        }

        .lcareer {
            display: flex;
            align-items: center;
            justify-content: flex-start; /* Align to the start as in desktop */
            font-size: 14px;
            text-transform: uppercase;
            margin-top: 15px;
            gap: 10px; /* Keep the same spacing between list items */
            width: 100%;
            margin-top: -15px;
        }
    
        /* Remove negative margin to match desktop behavior */
        .lcareer li {
            display: flex;
            gap: 8px; /* Keep consistent gap */
            align-items: center;
            padding: 0;
            font-size: 12px;
            margin-top: 0; /* Remove negative margin */
            margin-left: 10px;
           
           
        }
    
        /* Keep button positioned at the bottom */
        .custom-btn {
            width: 100%;
            max-width: 100%;
            margin-top: auto; /* Ensure button stays at the bottom */
            margin-bottom: 17x; /* Similar to desktop */
        }
    
        /* Maintain padding consistency for card body */
        .custom-card-body {
            padding-bottom: 24px; /* Match the desktop padding */
        }
    
        /* Adjust the custom-card width to maintain alignment on mobile */
        .custom-card {
            width: 100%; /* Take full width on mobile */
            height: auto; /* Ensure the height is responsive */
        }
        
        .solution-card {
            width: 100%;
            
            
        }
        .card-img-top{
            width: 100%;
            height: 200px;
        }  
    }
    @media (max-width: 768px) {
        .col-md-6 {
            padding: 0 15px; 
            
        }
        
        .font-size-36px {
            font-size: 28px; /* Adjust heading size for mobile */
            padding-left: 15px;
        }
        
        .font-size-22px {
            font-size: 21px; /* Adjust paragraph font size for mobile */
            line-height: 1.3; /* Improve readability */
            padding-left: 20px;
            width: 100%;
        }
        
        .font-size-24px {
            font-size: 20px; /* Adjust smaller paragraph font size */
            line-height: 1.6; /* Improve readability */
            padding-left: 20px;
            width: 100%;
        }
        
        .default-gray {
            color: #8a8a8a; /* Ensure the gray text color is applied */
        }
        
        
        .gap-3 {
            gap: 1rem; /* Adjust gap for better spacing */
        }
        
        .mt-3 {
            margin-top: 1.5rem; /* Adjust top margin */
        }
        
        .mb-4 {
            margin-bottom: 1rem; /* Adjust bottom margin */
        }
    }
    

}
   