@use '../../custom' as *;

.partners-section {
  .custom-text-secondary {
    color: gray;
  }

  .custom-text-black {
    color: black;
  }

  .custom-fs-1 {
    font-size: 36px;
  }

  .custom-fs-2 {
    font-size: 22px;
  }

  .custom-fs-3 {
    font-size: 18px;
  }

  .pb-120px {
    padding-bottom: 120px;
  }

  .p-48px {
    padding: 48px;
  }

  .mb-64px {
    margin-bottom: 64px
  }

  .mb-48px {
    margin-bottom: 64px
  }

  .custom-grid-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }

  }


  .partner-card {
    background-color: $white-200 !important;
    /* Set background to transparent */
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px;
    height: 380px !important;
  }

  .partner-card img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .custom-nav-tabs {
    border-bottom: 1.5px solid #ddd;
    justify-content: flex-start;

    .nav-item {
      button{
        font-size: 16px;
      }
      
      @media (max-width: 992px) {
        button {
          font-size: 14px;
        }
      }
      
    }
  }

  .custom-nav-tabs .custom-nav-link {
    font-weight: bold;
    color: #999;
    background-color: transparent;
    border: none;
    padding: 12px 24px;

  }

  .custom-nav-tabs .custom-nav-link.active {
    color: #8b0000;
    border-bottom: 3px solid #8b0000;
  }

  .custom-nav-tabs .custom-nav-link:hover {
    color: #8b0000;
  }

  .btn-discover {
    padding: 10px 20px;
    background-color: gray;
    border: none;
    color: black;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .btn-discover:hover {
    background-color: darkgrey;
  }

  .mhead {
    font-family: var(--default-font-light);
    letter-spacing: -0.75px;
    line-height: 43.2px;
    font-size: 36px;
    margin-bottom: 64px;
  }

  .connect-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 45px;
    margin-top: 25px;
    padding-bottom: 50px;
  }

  .connect-buttons a {
    border: 1px solid #4e4e4e;
    padding: 10px 10px;
    text-decoration: none;
    color: #4e4e4e;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, padding-right 0.3s;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .connect-buttons a svg {
    margin-left: 10px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
    transform: translateX(-10px);
    position: absolute;
    right: 10px;

  }

  .connect-buttons a:hover svg {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }

  .connect-buttons a:hover {
    background-color: rgba(116, 20, 27, 1);
    color: white;
    padding-right: 35px;
    justify-content: flex-start;
  }

  .custom-grid-row .partner-card {
    width: 100%;
  }

  .custom-grid-row .partner-card img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .btn.btn-discover {
    padding: 10px 20px;
    background-color: #D9D9D9;
    border: none;
    color: black;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    margin-top: 50px;
  }

  .btn.btn-discover:hover {
    background-color: darkgrey;
  }


  @media (max-width: 768px) {
    .connect-buttons {
      flex-direction: column;
      gap: 10px;
      width: 100%;
      /* Ensures the container is full width on mobile */
      padding: 0 15px;
      /* Optional padding for a slight margin from the edges */
    }

    .connect-buttons a {
      padding-right: 20px;
      padding: 15px;
      font-size: 16px;
      width: 100%;
      /* Ensures full width for each button */
    }

    .custom-fs-1 {
      font-size: 24px;
    }

    .custom-fs-2 {
      font-size: 18px;
    }

    .custom-fs-3 {
      font-size: 16px;
    }

    .mhead {
      font-size: 26px;
      line-height: 31.2px;
      margin-bottom: 40px;
      /* Optional: adjust line height for better spacing */
    }

    .partner-card {
      height: 250px !important;
      /* Decrease height for mobile */
    }
   

    .partners-swiper-container .partner-card {
      height: 200px;
      /* Adjust as needed */
      display: flex;
      align-items: center;
      /* Center the content vertically */
      justify-content: center;
      /* Center the content horizontally */
    }

    .partners-swiper-container .partner-card img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      /* Maintain aspect ratio */
    }
  }

  @media (max-width: 576px) {
    .partner-card {
      height: 163px !important;
    }
  }
  @media (max-width: 768px) {
    .about-section .about-section-1 {
      margin-top: 64px;
      margin-bottom: 128px;
      flex-direction: column;
      padding-left: 15px;
    }
  }


}