@use "../../components/buttons";
@use './../../components/card-with-details';

h2.our-process-heading {

    font-size: 24px;
    margin-top: 64px;
    margin-bottom: 40px;
}

@media screen and (min-width: 640px) {
    h2.our-process-heading {
        font-size: 28px;

    }
}

@media screen and (min-width: 768px) {
    h2.our-process-heading {
        font-size: 32px;
        margin-top: 0;
        margin-bottom: 64px;
    }
}

@media screen and (min-width: 1440px) {
    .h2.our-process-heading {
        font-size: 40px;
    }
}