@use '../../custom' as *;

.about-section {

    @media (max-width: 768px) {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }



    /* text color */
    .custom-text-secondary {
        color: $black-300;
    }

    .custom-text-black {
        color: $black-400;
    }

    .custom-text-red {
        color: $redcarpet-900;
    }

    /* font size */
    .custom-fs-1 {
        font-size: 36px;
        line-height: 44px;
    }

    .custom-fs-2 {
        font-size: 27px;
    }

    .custom-fs-3 {
        font-size: 22px;
        line-height: 30px;
    }

    .custom-fs-4 {
        font-size: 18px;
        line-height: 26px;
    }

    .custom-fs-5 {
        font-size: 16px;
    }

    .custom-fs-6 {
        font-size: 20px !important;
    }

    @media (max-width: 768px) {
        .custom-fs-1 {
            font-size: 24px;
        }

        .custom-fs-3 {
            font-size: 18px;
            line-height: 24px;
        }

        .custom-fs-4 {
            font-size: 16px;
        }
    }

    /* padding 120px */
    .p-120px {
        padding: 120px;
    }

    .pt-120px {
        padding-top: 120px;
    }

    .pb-120px {
        padding-bottom: 120px;
    }

    .ps-120px {
        padding-left: 120px;
    }

    .pe-120px {
        padding-right: 120px;
    }

    .px-120px {
        padding-left: 120px;
        padding-right: 120px;
    }

    .py-120px {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    /* padding 64px */
    .p-64px {
        padding: 64px;
    }

    .pt-64px {
        padding-top: 64px;
    }

    .pb-64px {
        padding-bottom: 64px;
    }

    .ps-64px {
        padding-left: 64px;
    }

    .pe-64px {
        padding-right: 64px;
    }

    .px-64px {
        padding-left: 64px;
        padding-right: 64px;
    }

    .py-64px {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    /* margin 120px */
    .m-120px {
        margin: 120px;
    }

    .mt-120px {
        margin-top: 120px;
    }

    .mb-120px {
        margin-bottom: 120px;
    }

    .ms-120px {
        margin-left: 120px;
    }

    .me-120px {
        margin-right: 120px;
    }

    .mx-120px {
        margin-left: 120px;
        margin-right: 120px;
    }

    .my-120px {
        margin-top: 120px;
        margin-bottom: 120px;
    }

    .mt-160px {
        margin-top: 160px;
    }

    /* margin 64px */
    .m-64px {
        margin: 64px;
    }

    .mt-64px {
        margin-top: 64px;
    }

    .mb-64px {
        margin-bottom: 64px;
    }

    .ms-64px {
        margin-left: 64px;
    }

    .me-64px {
        margin-right: 64px;
    }

    .mx-64px {
        margin-left: 64px;
        margin-right: 64px;
    }

    .my-64px {
        margin-top: 64px;
        margin-bottom: 64px;
    }

    /* line height */
    .lh-44px {
        line-height: 44px;
    }

    .lh-30px {
        line-height: 30px;
    }

    .lh-26px {
        line-height: 26px;
    }

    /* font weight */
    .fw-500 {
        font-weight: 500;
    }

    /* timeline */



    .swiper-container-wrapper--timeline .swiper-pagination-progressbar {
        position: relative;
        margin-bottom: 70px;
        background-color: transparent;
        height: 4px;
        border-bottom: 1px solid $black-300;
        width: 100%;
        z-index: 4;
    }

    .swiper-container-wrapper--timeline .swiper-pagination-progressbar-fill {
        background-color: $redcarpet-900;
        height: 3px;
        top: 2px;
    }

    .swiper-container-wrapper--timeline .swiper-pagination-progressbar:before {
        position: absolute;
        top: 2px;
        /* left: -100%;
      width: 100%; */
        height: 3px;
        background-color: $black-400;
        content: "";
    }

    .swiper-container-wrapper--timeline .swiper-pagination-progressbar:after {
        position: absolute;
        top: 3px;
        /* right: -100%;
      width: 100%; */
        height: 1px;
        background-color: $black-300;
        content: "";
    }

    .swiper-container-wrapper--timeline .swiper-pagination-custom {
        position: relative;
        list-style: none;
        margin: 1rem 0;
        /* padding: 0; */
        display: flex;
        line-height: 1.66;
        bottom: 0;
        z-index: 4;
        width: 80%;
        display: flex;
    }

    .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch {
        position: relative;
        width: 100%;
        height: 30px;
        line-height: 30px;
        display: block;
        font-family: "nb_international_promono";
    }

    .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch .switch-title {
        position: absolute;
        font-weight: 400;
        right: 0;
        transform: translateX(50%);
        transition: 0.2s all ease-in-out;
        transition-delay: 0s;
        cursor: pointer;
        z-index: 1;
        color: $black-300;
    }

    .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch .switch-title:after {
        position: absolute;
        top: calc(100% + 19px);
        right: 50%;
        transform: translateX(50%) translateY(-50%);
        width: 10px;
        height: 10px;
        background: $redcarpet-900;
        border-radius: 2rem;
        content: "";
        transition: 0.2s all ease-in-out;
        transition-delay: 0s;
        z-index: 1;
    }

    .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch.active .switch-title {
        font-weight: 400;
        transition-delay: 0.4s;
        font-size: 16px;
    }

    .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch.active .switch-title:after {
        background: $redcarpet-900;
        width: 10px;
        height: 10px;
        transition-delay: 0.4s;
    }

    .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch.active~.swiper-pagination-switch .switch-title {
        color: $black-300;
        font-weight: 16px;
    }

    .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch.active~.swiper-pagination-switch .switch-title:after {
        background: $black-300;
    }

    .swiper-container-wrapper--timeline .active .switch-title {
        color: $redcarpet-900 !important;
        font-weight: bold !important;
    }

    .swiper-container-wrapper--timeline .swiper-button-next,
    .swiper-container-wrapper--timeline .swiper-button-prev {
        position: absolute;
        top: 30%;
        width: 40px;
        height: 40px;
        margin-top: -20px;
        z-index: 5;
        cursor: pointer;
        color: $black-400;
        /* Arrow color */
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        font-size: 20px;
        border: 1px solid $white-300;
        border-radius: 5px;
        transition: all 0.3s ease;

        &:hover {
            background-color: $primary !important;
            color: $white-200;
        }
    }



    .swiper-container-wrapper--timeline .swiper-button-next {
        right: 10px;
    }

    .swiper-container-wrapper--timeline .swiper-button-prev {
        left: 10px;
    }

    .swiper-container-wrapper--timeline .swiper-button-next.swiper-button-disabled,
    .swiper-container-wrapper--timeline .swiper-button-prev.swiper-button-disabled {
        opacity: 0.4;
        pointer-events: none;

        @media (max-width: 768px) {
            opacity: 1;
            color: $white-400;
        }
    }

    .swiper-container-wrapper--timeline .swiper-button-next:after,
    .swiper-container-wrapper--timeline .swiper-button-prev:after {
        font-size: 20px;
        font-weight: bold;
    }

    .swiper-container-wrapper--timeline.swiper-container-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .swiper-container-wrapper--timeline .swiper {
        margin-left: unset;
        margin-right: unset;
    }

    .swiper-button-prev-mobile {
        top: 65px !important;
        left: 0px !important;
        z-index: 5 !important;
        background-color: $white-200 !important;
    }

    .swiper-button-next-mobile {
        top: 65px !important;
        right: 0px !important;
        z-index: 5 !important;
        background-color: $white-200 !important;
    }

    /* Desktop view: show desktop arrows, hide mobile arrows */
    @media (min-width: 768px) {

        .swiper-button-prev-mobile,
        .swiper-button-next-mobile {
            display: none !important;
        }

        .swiper-button-prev-desktop,
        .swiper-button-next-desktop {
            display: flex !important;
        }
    }

    /* Mobile view: show mobile arrows, hide desktop arrows */
    @media (max-width: 767px) {

        .swiper-button-prev-desktop,
        .swiper-button-next-desktop {
            display: none !important;
        }

        .swiper-button-prev-mobile,
        .swiper-button-next-mobile {
            display: flex !important;
        }
    }

    .about-image {
        height: 400px;
        width: 572px;
        padding: 0;

        img {
            border-radius: 4px;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        @media (max-width: 576px) {
            margin-top: 20px;
            padding: 0 16px;
        }
    }

    .swiper-pagination-custom-mobile {
        display: flex;
        list-style: none;
        margin-top: 35px;
        position: relative;
        justify-content: center;
        color: $redcarpet-900;

        li {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5px;
            transform: translate(-20px,-10px);

            span {
                font-size: 14px;
                font-family: "nb_international_promono";
            }

            i {
                font-size: 20px;
            }
        }
    }

    .swiper-progressbar-mobile {
        margin-bottom: 70px;
        display: flex;
        justify-content: center;
        align-items: center;

        hr {
            width: 70%;
        }

        
    }



    .ceo {
        &-container {
            display: flex;
            gap: 64px;
            justify-content: center;
            margin-bottom: 120px;
            margin-top: 120px;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            gap: 40px;

            &-image {
                
                height: 100%;
                width: 335px;
                border-radius: 4px;

                img {
                    width: 100%;
                    border-radius: 4px;
                    height: 100%;
                    object-fit: cover;
                    object-position: 50% 20%;
                }
            }

            &-title {
                display: none;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            gap: 48px;

            &-title {
                font-weight: bold;
                font-size: 36px;

            }

            &-desc {
                display: flex;
                flex-direction: column;
                gap: 30px;
                line-height: 30px;
                
                &-main {
                    font-size: 22px;
                    
                    font-weight: bold;
                }

                &-sub1,&-sub2 {
                    font-size: 18px;

                    color: $black-300;
                }
            }
        }
    }
    @media (max-width: 1200px) {
        .ceo {
            &-container {
                flex-direction: column-reverse;
                margin-bottom: 164px;
                gap: 48px;
                margin-top: 64px;
            }
            &-content {
                &-image {
                    box-shadow: 306px 197px 102px 0px rgba(169, 168, 167, 0.01), 196px 126px 93px 0px rgba(169, 168, 167, 0.06), 110px 71px 79px 0px rgba(169, 168, 167, 0.20), 49px 32px 58px 0px rgba(169, 168, 167, 0.34), 12px 8px 32px 0px rgba(169, 168, 167, 0.39);
                    height: 528px;
                    width: 100%;
                }

                &-title {
                    display: flex;
                }
            }

            &-info {
                &-title {
                    font-size: 24px;
                }
                &-desc {
                    line-height: 24px;
                    &-main {
                        font-size: 18px
                    }

                    &-sub1,&-sub2 {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .fw-regular {
        font-family: "nb_international_proregular";
    }

    .about-swiper {
        overflow: hidden;
    }

    .timeline {

        &-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 24px;
            margin-bottom: 128px;

            &_text {
                font-size: 36px;
            }

            &_desc {
                font-size: 22px;
                width: 656px;
                line-height: 30px;
            }
        }

        &-item {
            display: flex;
            gap: 64px;
            margin: 0 100px;
            justify-content: center;
            align-items: center;
        }

        &-content {
            width: 100%;
            max-width: 493px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            flex: 1 1 0;

            &-title {
                font-size: 27px;
                font-weight: bold;
            }
            &-desc {
                &-main {
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 12px;
                    line-height: 26px;
                }

                &-sub {
                    font-size: 16px;
                    color: $black-300;
                    line-height: 22px;
                }
            }
        }

        &-image {
            height: 360px;
            width: 453px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 4px;
            }
        }
    }

    @media (max-width: 1200px) {
        .timeline-image {
            width: 300px;
        }
    }

    @media (max-width: 992px) {
        .timeline-item {
            flex-direction: column;
            gap: 40px;
        }
        .timeline-image {
            width: 100%;
        }
    }

    @media (max-width: 768px) {
        .timeline {
            &-title {
                margin-bottom: 40px;
                &_text {
                    font-size: 24px;
                }
                &_desc {
                    font-size: 18px;
                    width: 100%;
                    line-height: 24px;
                }
            }
            &-item {
                margin: 0;
            }

            &-content {
                max-width: 100%;
                gap: 40px;

                &-title {
                    font-size: 18px;
                }

                &-desc {
                    
                    &-main {
                        font-size: 16px;
                    }
    
                    &-sub {
                        font-size: 14px;
                    }
                }
            }
            
        }
    }

    .strategy {
        display: flex;
        flex-direction: column;
        gap: 64px;
        justify-content: center;
        align-items: center;
        margin-bottom: 120px;

        &-head {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 24px;
            text-align: center;

            &_title {
                font-size: 36px;
            }
            &_desc {
                font-size: 22px;
                max-width: 776px;
                color: $black-300;
                line-height: 30px;
            }
        }
        &-content {
            display: flex;
            gap: 20px;
            align-items: center;
            width: 100%;
            &-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 48px 24px;
                gap: 48px;
                width: 100%;

                &_text {
                    font-size: 22px;
                    line-height: 30px;
                    font-weight: 400;
                }
            }
        }
    }
    @media (max-width: 992px) {
        .strategy {
            gap: 40px;
            &-head {
                &_title {
                    font-size: 24px;
                }
                &_desc {
                    font-size: 18px;
                    max-width: 100%;
                    line-height: 24px;
                }
            }
            
            &-content {
                flex-direction: column;
                gap: 16px;

                &-item {
                    align-items: center;
                    padding: 48px;
                    border: 0.5px solid $white-400;
                    height: 236px;

                    &_text {
                        text-align: center;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

        }
    }

    .passion {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 64px;
        margin-bottom: 160px;
        margin-top: 150px;

        &-head {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 24px;
            text-align: center;

            &_title {
                font-size: 36px;
            }
            &_desc {
                font-size: 22px;
                max-width: 776px;
                color: $black-300;
                line-height: 30px;
            }
        }

        &-card-item {
            padding: 48px;
            border-radius: 4px;
            height: 340px;
            border: 1px solid $white-300;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &_text {
                text-align: center;
                font-size: 27px;
                font-weight: 400;
                font-family: "nb_international_proregular";
                width: 402px;
                letter-spacing: -0.5px;
                line-height: 33px;
                
            }
        }
    }

    @media (max-width: 768px) {
        .passion {
            margin-bottom: 128px;
            margin-top: 96px;
            gap: 20px;
            &-head {
                text-align: left;
                &_title {
                    font-size: 24px;
                }
                &_desc {
                    font-size: 18px;
                    max-width: 100%;
                    line-height: 24px;
                }
            }

            &-card-item {
                height: 170px;
                padding: 24px;
                &_text {
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: normal;
                    width: 100%;
                }
            }
        }
    }

    .about-section-1 {
        margin-top: 30px;
        margin-bottom: 120px;
        display: flex;
        gap: 64px;
        .about-statistics {
            flex: 1;
        }
    }

    .about-section-2 {
        display: flex;
        justify-content: space-between;
        gap: 68px;

        .sec-2-text {
            max-width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 24px;
        }

        .sec-2-img {
            max-width: 50%;
            height: 400px;
            width: 572px;
            border-radius: 4px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 4px;
            }
        }

    }

    .magrin-sec2 {
        margin-top: 160px;
        margin-bottom: 64px;
    }

    .our-strategy-desc {
        max-width: 776px;
    }

    .strategy-card {
        width: 570px;
    }

    @media (max-width: 992px) {
        .about-section-1 {
            flex-direction: column;
            gap: 40px;
        }
        .about-section-2 {
            flex-direction: column-reverse;
            gap: 20px;

            .sec-2-img {
                max-width: 100%;
                width: auto;
            }

            .sec-2-text {
                max-width: 100%;
            }

            .magrin-sec2 {
                margin-top: 64px;
            }
        }

        .magrin-sec2 {
            margin-top: 96px;
            margin-bottom: 120px;
        }
    }

    .about-section-3 {
        padding: 160px 0 40px 0;
    }

    .about-section-4 {
        padding: 120px 0 120px 0;
    }

    .about-section-6 {
        padding: 80px 0 120px 0;
    }



    @media (max-width: 768px) {
        .about-section-1 {
            margin-top: 64px;
            margin-bottom: 128px;
            flex-direction: column;
        }

        .about-section-3 {
            padding: 120px 0 120px 0;
        }

        .about-section-4 {
            padding: 0 0 120px 0;
        }

        .about-section-6 {
            padding: 60px 0 120px 0;
        }
    }
}