/* NEUBAU WEBFONTS: NB INTERNTIONAL PRO EDITION, MMXXI */



@font-face {
    font-family: 'nb_international_probold';
    src: url('../assets/fonts/nbint_pro_web/nbinternationalprobol-webfont.eot');
    src: url('../assets/fonts/nbint_pro_web/nbinternationalprobol-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/nbint_pro_web/nbinternationalprobol-webfont.woff2') format('woff2'),
         url('../assets/fonts/nbint_pro_web/nbinternationalprobol-webfont.woff') format('woff'),
         url('../assets/fonts/nbint_pro_web/nbinternationalprobol-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nb_international_proBkIt';
    src: url('../assets/fonts/nbint_pro_web/nbinternationalprobooita-webfont.eot');
    src: url('../assets/fonts/nbint_pro_web/nbinternationalprobooita-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/nbint_pro_web/nbinternationalprobooita-webfont.woff2') format('woff2'),
         url('../assets/fonts/nbint_pro_web/nbinternationalprobooita-webfont.woff') format('woff'),
         url('../assets/fonts/nbint_pro_web/nbinternationalprobooita-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nb_international_probook';
    src: url('../assets/fonts/nbint_pro_web/nbinternationalproboo-webfont.eot');
    src: url('../assets/fonts/nbint_pro_web/nbinternationalproboo-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/nbint_pro_web/nbinternationalproboo-webfont.woff2') format('woff2'),
         url('../assets/fonts/nbint_pro_web/nbinternationalproboo-webfont.woff') format('woff'),
         url('../assets/fonts/nbint_pro_web/nbinternationalproboo-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nb_international_proBdIt';
    src: url('../assets/fonts/nbint_pro_web/nbinternationalprobolita-webfont.eot');
    src: url('../assets/fonts/nbint_pro_web/nbinternationalprobolita-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/nbint_pro_web/nbinternationalprobolita-webfont.woff2') format('woff2'),
         url('../assets/fonts/nbint_pro_web/nbinternationalprobolita-webfont.woff') format('woff'),
         url('../assets/fonts/nbint_pro_web/nbinternationalprobolita-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nb_international_proregular';
    src: url('../assets/fonts/nbint_pro_web/nbinternationalproreg-webfont.eot');
    src: url('../assets/fonts/nbint_pro_web/nbinternationalproreg-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/nbint_pro_web/nbinternationalproreg-webfont.woff2') format('woff2'),
         url('../assets/fonts/nbint_pro_web/nbinternationalproreg-webfont.woff') format('woff'),
         url('../assets/fonts/nbint_pro_web/nbinternationalproreg-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nb_international_promono';
    src: url('../assets/fonts/nbint_pro_web/nbinternationalpromono-webfont.eot');
    src: url('../assets/fonts/nbint_pro_web/nbinternationalpromono-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/nbint_pro_web/nbinternationalpromono-webfont.woff2') format('woff2'),
         url('../assets/fonts/nbint_pro_web/nbinternationalpromono-webfont.woff') format('woff'),
         url('../assets/fonts/nbint_pro_web/nbinternationalpromono-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nb_international_proMdIt';
    src: url('../assets/fonts/nbint_pro_web/nbinternationalpromedita-webfont.eot');
    src: url('../assets/fonts/nbint_pro_web/nbinternationalpromedita-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/nbint_pro_web/nbinternationalpromedita-webfont.woff2') format('woff2'),
         url('../assets/fonts/nbint_pro_web/nbinternationalpromedita-webfont.woff') format('woff'),
         url('../assets/fonts/nbint_pro_web/nbinternationalpromedita-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nb_international_promedium';
    src: url('../assets/fonts/nbint_pro_web/nbinternationalpromed-webfont.eot');
    src: url('../assets/fonts/nbint_pro_web/nbinternationalpromed-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/nbint_pro_web/nbinternationalpromed-webfont.woff2') format('woff2'),
         url('../assets/fonts/nbint_pro_web/nbinternationalpromed-webfont.woff') format('woff'),
         url('../assets/fonts/nbint_pro_web/nbinternationalpromed-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nb_international_proLtIt';
    src: url('../assets/fonts/nbint_pro_web/nbinternationalproligita-webfont.eot');
    src: url('../assets/fonts/nbint_pro_web/nbinternationalproligita-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/nbint_pro_web/nbinternationalproligita-webfont.woff2') format('woff2'),
         url('../assets/fonts/nbint_pro_web/nbinternationalproligita-webfont.woff') format('woff'),
         url('../assets/fonts/nbint_pro_web/nbinternationalproligita-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nb_international_prolight';
    src: url('../assets/fonts/nbint_pro_web/nbinternationalprolig-webfont.eot');
    src: url('../assets/fonts/nbint_pro_web/nbinternationalprolig-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/nbint_pro_web/nbinternationalprolig-webfont.woff2') format('woff2'),
         url('../assets/fonts/nbint_pro_web/nbinternationalprolig-webfont.woff') format('woff'),
         url('../assets/fonts/nbint_pro_web/nbinternationalprolig-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nb_international_proitalic';
    src: url('../assets/fonts/nbint_pro_web/nbinternationalproita-webfont.eot');
    src: url('../assets/fonts/nbint_pro_web/nbinternationalproita-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/nbint_pro_web/nbinternationalproita-webfont.woff2') format('woff2'),
         url('../assets/fonts/nbint_pro_web/nbinternationalproita-webfont.woff') format('woff'),
         url('../assets/fonts/nbint_pro_web/nbinternationalproita-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}