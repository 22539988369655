@use '../../custom' as *;

.bcrum {
    margin-top: 64px;
    margin-bottom: 64px;
    
}

.bcrum ul {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    gap: 15px;
    list-style: none;
    font-family: "nb_international_promono" !important;
    padding-left: 0;
    font-size: 14px;
    text-transform: uppercase;
    
}

.bcrum ul li a {
    color: $black-300;
    text-decoration: none;
    
}

.bcrum ul li a.active {
    color: $redcarpet-900;
    text-decoration: none;
}

.bcrum ul i {
    color: $black-300;
}
