@font-face {
  font-family: "icon";
  src: url('icon.eot?t=1728318014331'); /* IE9*/
  src: url('icon.eot?t=1728318014331#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("icon.woff2?t=1728318014331") format("woff2"),
  url("icon.woff?t=1728318014331") format("woff"),
  url('icon.ttf?t=1728318014331') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('icon.svg?t=1728318014331#icon') format('svg'); /* iOS 4.1- */
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icon' !important;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-arrow-right:before { content: "\ea01"; }
.icon-chatbot:before { content: "\ea02"; }
.icon-chevron-down:before { content: "\ea03"; }
.icon-chevron-right:before { content: "\ea04"; }
.icon-facebook:before { content: "\ea05"; }
.icon-filter-chevron-down:before { content: "\ea06"; }
.icon-globe:before { content: "\ea07"; }
.icon-hamburger:before { content: "\ea08"; }
.icon-instagram:before { content: "\ea09"; }
.icon-magnifying-glass:before { content: "\ea0a"; }
.icon-solid-circle:before { content: "\ea0b"; }
.icon-twitter:before { content: "\ea0c"; }
.icon-youtube:before { content: "\ea0d"; }


