@use '../../custom' as *;

.chatbot-icon-container {
  position: fixed;
  bottom: 50px;
  right: 32px;
  cursor: pointer; 
  transition: transform 0.3s ease-in-out; 
  z-index: 9;

  &:hover {
    transform: scale(1.2); 
  }
}
