@use "../../custom" as *;

.hamburger-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 200vw;
  height: 200vh;
  z-index: calc($navbar-z-index + 1);
  background-color: rgba(0,0,0,0.4);
}

.hamburger-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  width: 100%;
  max-width: 430px;
  height: 100%;
  background-color: $redcarpet-600;
  z-index: calc($navbar-z-index + 2);
  transition: right 0.3s ease-in-out;

  .hamburger-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: $white-200;
    z-index: inherit;
  }
}

.hamburger-header {
  height: $navbar-height-mv;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;

  .hamburger-toggle-icon {
    font-size: 18px;
  }

  .hamburger-actions {
    display: flex;
    align-items: center;
    column-gap: 1.25rem;
  }
}

// HAMBURGER LANGUAGE MENU

.ham-language-menu-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  width: 100%;
  max-width: 430px;
  height: 100%;
  z-index: calc($navbar-z-index + 2);
  transition: right 0.3s ease-in-out;

  .lang-menu-container-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $white-200;
    z-index: inherit;

    .lang-menu-header {
      height: $navbar-height-mv;
      width: 100%;
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;

      span {
        font-family: "nb_international_probold";
        font-size: 14px;
        color: $thamarblack-400;
      }
    }

    .lang-menu-data {
      display: flex;
      width: 100%;
      height: 100%;

      ul,
      li {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      .language-change-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;
        width: 100%;
        height: 100%;
        padding: 1rem;
        border-radius: 5px;

        .menu-item-container {
          width: 100%;
          height: 50px;
          background-color: #ffffff;
          border: 1px solid $black-200;
          border-radius: 5px;

          &.active {
            border: 0.5px solid $primary;
          }

          a {
            text-decoration: none;
          }

          .menu-item {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0.5rem;
            column-gap: 1rem;

            img {
              border-radius: 50%;
              width: 30px;
              height: 30px;
              object-fit: cover;
            }

            span {
              color: #000000;
              font-size: 18px;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}

// HAMBURGER SEARCH MENU

.ham-search-menu-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  width: 100%;
  max-width: 430px;
  height: 100%;
  z-index: calc($navbar-z-index + 2);
  transition: right 0.3s ease-in-out;

  .search-menu-container-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $white-200;
    z-index: inherit;

    .search-menu-header {
      height: $navbar-height-mv;
      width: 100%;
      flex-shrink: 0;
      display: flex;
      justify-content: end;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
    }

    .search-menu-data {
      width: 100%;
      height: 100%;
      padding: 28px;

      .ham-search-action-form {
        position: relative;
        width: 100%;
        height: 100%;

        .ham-search-form-input {
          position: absolute;
          top: 0;
          right: 0px;
          height: 50px;
          width: 100%;
          background-color: #fffffe;
          padding-left: 10px;
          padding-right: 30px;
          border: 1px solid $black-300;
          border-radius: 5px;
          font-size: 14px;

          &:focus {
            outline: none;
            border: 1px solid $black-300;
          }
        }

        .search-form-button-with-icon {
          position: absolute;
          top: 25px;
          right: 10px;
          transform: translateY(-50%);
          height: 16px;
          width: 16px;
          padding: 0;
          font-size: 0;
          background-color: transparent;
          border: none;
          cursor: pointer;

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }

        .live-search-results-container {
          position: absolute;
          top: 70px;
          left: 0;
          width: 100%;
          //height: 305px;
          max-height: 450px;
          padding: 0.75rem;
          background-color: #fffffe;
          box-shadow: 42px 20px 102px 0px #0000001a,
            168px 78px 185px 0px #00000017, 377px 176px 250px 0px #0000000d,
            671px 312px 296px 0px #00000003, 1048px 488px 324px 0px #00000000;
          border-radius: 5px;

          overflow-y: scroll;

          ::-webkit-scrollbar {
            width: 0;
            height: 0;
          }

          /* For Firefox */
          scrollbar-width: none;
          /* Hide scrollbar in Firefox */

          /* For Internet Explorer and Edge */
          -ms-overflow-style: none;
          /* Hide scrollbar in IE and Edge */

          .container-header {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: start;
            color: $black-400;
            justify-content: center;
            font-family: "nb_international_proregular";
            font-size: 18px;
            border-bottom: 2px solid $white-400;
            cursor: default;
          }

          .container-content {
            min-height: min-content;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            font-family: "nb_international_prolight";
            color: $black-400;

            .result-item-link {
              max-width: 100%;
              text-decoration: none;
              color: $black-400;
              padding: 1rem 0.5rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;

              .result-item {
                height: 40px;

                .highlight-keyword {
                  color: $redcarpet-800;
                  font-family: "nb_international_proregular";
                  font-size: 18px;
                }
              }
            }
          }

          .container-footer {
            .see-all-res-button {
              background-color: transparent;
              border: none;
              padding: 0.5rem;
              text-decoration: none;
              color: $black-400;

              &:hover,
              &:active,
              &:focus {
                outline: none;
                box-shadow: none;
              }

              .see-all-res-button-container {
                display: flex;
                align-items: center;
                column-gap: 1rem;
                font-size: 14px;

                img {
                  height: 21px;
                  width: 16px;
                  object-fit: contain;
                }
              }
            }

            .no-results {
              width: 100%;
              height: 100%;
              padding: 1rem;
              color: $black-400;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.hamburger-header-icons {
  font-size: $nav-menu-action-fs;
  width: 16px;

  &.ham_hamburger_close_icon,
  &.ham_search_menu_close_icon,
  &.ham_language_menu_close_icon {
    width: 12px;
  }
}

.hamburger-data {
  flex-grow: 1;
  max-height: 100%;
  padding: 0 1rem;
  overflow-y: auto;

  a {
    text-decoration: none;
  }

  .hamburger-navigation {
    width: 100%;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid $redcarpet-500;
    margin-bottom: 1.5rem;
    font-size: 16px;
  }

  .hamburger-learnmore-section {
    width: 100%;
    font-size: 14px;

    h5 {
      font-family: "nb_international_proregular";
      font-size: 18px;
      margin-left: 1rem;
    }
  }

  .hamburger-nav-link {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "nb_international_proregular";
    //font-weight: bold;
    //font-size: 16px;

    img {
      width: 14px;
    }
  }

  /* Hide scrollbar for webkit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  /* For Firefox */
  scrollbar-width: none;
  /* Hide scrollbar in Firefox */

  /* For Internet Explorer and Edge */
  -ms-overflow-style: none;
  /* Hide scrollbar in IE and Edge */
}

.hamburger-contact-section {
  margin-top: 1rem;
  padding: 1rem;

  h6 {
    font-family: "nb_international_proregular";
    font-size: 16px;
    margin-bottom: 20px;
  }

  address {
    display: flex;
    align-items: start;
    column-gap: 0.55rem;

    p {
      font-size: 12px;
    }
  }

  .phone-number {
    a {
      display: flex;
      column-gap: 0.55rem;

      span {
        font-size: 12px;
      }
    }
  }
}

.hamburger-footer {
  flex-shrink: 0;
  display: flex;
  height: $navbar-height-mv;
  width: 100%;
  justify-content: start;
  align-items: center;
  padding: 0 2rem;

  .footer-social-links {
    display: flex;
    font-size: 28px;
    align-items: center;
    column-gap: 2.5rem;

    a {
      text-decoration: none;
    }

    img {
      width: 24px;
    }
  }
}

.hamburger-container.toggle-hamburger {
  right: 0;
}

.ham-language-menu-container.toggle-ham-language-menu {
  right: 0;
}

.ham-search-menu-container.toggle-ham-search-menu {
  right: 0;
}

@media screen and (min-width: 576px) {
  .hamburger-data {
    padding: 0 2rem;
  }

  .hamburger-footer {
    padding: 0 3rem;

    .footer-social-links {
      img {
        width: 26px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .hamburger-data {
    padding: 0 3rem;
  }

  .hamburger-footer {
    padding: 0 4rem;

    .footer-social-links {
      img {
        width: 28px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .hamburger-container {
    width: 45%;
    max-width: 550px;
  }

  .hamburger-header {
    justify-content: flex-end;
    height: $navbar-height;
    padding-left: 35px;
    padding-right: 35px;
  }

  .hamburger-data {
    padding: 0 4rem;

    .hamburger-navigation {
      display: none;
    }
  }

  .hamburger-header .hamburger-actions {
    display: none;
  }

  .hamburger-footer {
    height: $navbar-height;
    padding: 0 5rem;

    .footer-social-links {
      font-size: 32px;

      img {
        width: 30px;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .hamburger-data {
    padding: 0 5rem;
  }

  .hamburger-footer {
    padding: 0 6rem;
  }
}