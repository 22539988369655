 /*sooraj*/

 @use "../../custom" as *;

 #solutions-listing {
     .text-justify {
         text-align: justify;
     }

     .fs-14 {
         font-size: 14px;
     }

     .fs-16 {
         font-size: 16px;
         color: var(--default-gray)
     }


     .sol-list-grid {
         display: flex;
         flex-wrap: wrap;
         margin-left: -10px;
         /* Half of the gutter (negative margin) */
         margin-right: -10px;
         /* Half of the gutter (negative margin) */
     }

     .sol-list-grid .col-lg-4,
     .sol-list-grid .col-md-6 {
         padding-left: 10px;
         /* Half of the gutter */
         padding-right: 10px;
         /* Half of the gutter */
         margin-bottom: 20px;
         /* Space between rows */
     }



     /* Solution styles for cards */
     .solution-card {
         text-decoration: none !important;
         transition: all ease-out 0.5s;
         min-height: 388px;
         height: 100%;

         &:hover {
             box-shadow: 121px 146px 53px 0px rgba(0, 0, 0, 0.00), 77px 93px 49px 0px rgba(0, 0, 0, 0.01), 44px 53px 41px 0px rgba(0, 0, 0, 0.05), 19px 23px 30px 0px rgba(0, 0, 0, 0.09), 5px 6px 17px 0px rgba(0, 0, 0, 0.10);
         }


         .card-body {
             padding: 24px;

             .card-title {
                 font-size: 22px;
                 font-weight: bold;
                 margin-bottom: 12px;
             }

             p {
                 font-size: 16px;
                 color: $black-300;
                 display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
             }
         }

         img {
             width: 100%;
             height: 232px;
             object-fit: cover;
         }
     }

     @media (max-width: 768px) {

         .sol-list-grid {
             margin-left: -8px;
             margin-right: -8px;
         }

         .sol-list-grid .col-lg-4,
         .sol-list-grid .col-md-6 {
             padding-left: 8px;
             padding-right: 8px;
             margin-bottom: 16px;
         }

         .solution-card {
             .card-body {
                 padding: 20px;

                 .card-title {
                     font-size: 16px;
                 }

                 p {
                     font-size: 14px;
                 }
             }

             img {
                 height: 247px;
             }
         }
     }


     /* .solution-card img {
        height: 200px;
        object-fit: cover;
    } */

     .solution-card .custom-btn {
         background-color: var(--default-cream);
         width: 100%;
         text-align: center;
     }

     .p-100 {
         padding-top: 0px;
         padding-bottom: 125px;
     }

     .connect-section {
         text-align: center;
         margin-bottom: 40px;
         margin-top: 60px;
     }

     .connect-section h2 {
         /* font-size: 28px;
    font-weight: 500; */
         margin-bottom: 20px;
         max-width: 305px;
     }

     .connect-buttons {
         display: flex;
         justify-content: center;
         gap: 20px;
         margin-bottom: 45px;
         margin-top: 25px;
     }

     .connect-buttons a {
         border: 1px solid #4e4e4e;
         padding: 10px 20px;
         text-decoration: none;
         color: #4e4e4e;
         border-radius: 5px;
         transition: background-color 0.3s, color 0.3s, padding-right 0.3s;
         position: relative;
     }

     .connect-buttons a i {
         margin-left: 10px;
         opacity: 0;
         visibility: hidden;
         transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
         transform: translateX(-10px);
         /* Hide the arrow initially */
     }

     .connect-buttons a:hover i {
         opacity: 1;
         visibility: visible;
         transform: translateX(0);
         /* Bring the arrow into view */
     }

     .connect-buttons a:hover {
         background-color: rgba(116, 20, 27, 1);
         color: white;
         padding-right: 35px;
     }

     .custom-form-container {
         max-width: 850px;
         margin: 55px auto;
         padding: 0;
     }

     #phone {
         width: 100%;
         padding: 10px;
         border: 0px solid #ccc !important;
         border-radius: 5px;
         font-size: 16px;
         height: 100%;
     }

     .custom-select-container,
     .custom-textarea-container,
     .custom-input-container {
         border: 1px solid #e0e0e0;
         padding: 10px 15px;
         margin-bottom: 20px;
         display: flex;
         justify-content: space-between;
         align-items: center;
     }

     .custom-select-container {
         margin-bottom: 0;
     }

     .custom-select {
         border: none;
         padding: 10px 0;
         font-size: 1rem;
         width: 100%;
         background-color: transparent;
     }

     .textarea-wrapper {
         position: relative;
         width: 100%;
     }

     .custom-textarea {
         width: 100%;
         border: none;
         resize: none;
         font-size: 1rem;
         background-color: transparent;
     }

     .optional-text {
         position: absolute;
         bottom: 10px;
         right: 15px;
         font-size: 0.875rem;
         color: #888;
         pointer-events: none;
     }

     .section-header {
         text-align: center;
         font-size: 24px;
         margin: 40px 0;
         color: #000;
     }

     .custom-input {
         border: none;
         padding: 10px 0;
         font-size: 1rem;
         width: 100%;
         background-color: transparent;
     }

     /* Remove input focus shadow */
     .custom-select:focus,
     .custom-textarea:focus,
     .custom-input:focus {
         outline: none;
         box-shadow: none;
     }

     .custom-textarea::placeholder,
     .custom-input::placeholder {
         color: #000;
         /* Black color for the placeholder */
     }

     /* new css  */
     .intl-tel-input .iti__flag-container {
         background-color: transparent;
         /* Remove the background color */
         border: none;
         /* Remove any border if exists */
     }

     .intl-tel-input input#phone {
         border: none;
         /* Remove border from phone input */
         border-radius: 0;
         /* Remove any border radius */
         padding: 10px;
         font-size: 16px;
         width: 100%;
     }

     .contact-inputs-row {
         display: flex;
         justify-content: space-between;
         gap: 20px;
     }

     .custom-input-container {
         flex: 1;
         display: flex;
         align-items: stretch;
     }

     .custom-input-container input {
         width: 100%;
         padding: 10px;
         font-size: 16px;
         border: 1px solid #ccc;
         /* Keep the border for other inputs */
         border-radius: 5px;
     }

     .intl-tel-input {
         display: flex;
         align-items: center;
         width: 100%;
         border-bottom: 1px solid #ccc;
         /* Add a line between country code and phone number */
     }

     /* Specific styling to ensure no background on flag container */
     .intl-tel-input .iti__flag-container {
         background: transparent;
         /* Ensure no background for the flag area */
     }

     .intl-tel-input input#phone {
         border-left: 1px solid #ccc;
         /* Add a vertical line between the country code and phone number */
         padding-left: 10px;
     }

     @media (max-width: 575px) {
         .listing-wrapper {
             padding-top: 64px;
         }
     }

     @media (max-width: 767px) {
         .custom-form-container {
             margin: 20px;
         }

         .custom-select-container,
         .custom-textarea-container,
         .custom-input-container {
             padding: 10px;
         }

         .custom-select {
             font-size: 1rem;
             padding: 8px 0;
         }

         .textarea-wrapper .optional-text {
             font-size: 0.75rem;
             /* Smaller optional text */
             bottom: 8px;
             right: 10px;
         }

         .section-header {
             font-size: 20px;
             margin: 20px 0;
         }

         .custom-input-container input {
             padding: 10px;
             font-size: 1rem;
         }

         .contact-inputs-row {
             flex-direction: column;
             /* Stack the phone and email inputs */
             gap: 15px;
         }

         .iti--separate-dial-code .iti__selected-flag {
             background-color: transparent !important;
         }

         .intl-tel-input {
             flex-direction: row;
             border-bottom: none;
             border-left: 1px solid #ccc;
             /* Adjust the border to match the design */
         }

         .intl-tel-input input#phone {
             padding-left: 15px;
             font-size: 16px;
         }

         /* Adjust button sizes and layout for mobile */
         .form-buttons {
             display: flex;
             justify-content: space-between;
             margin-top: 20px;
         }

         /* Optional spacing and alignment */
         .custom-select-container,
         .custom-textarea-container,
         .custom-input-container {
             align-items: center;
         }
     }

     .section-header {
         text-align: center;
         font-size: 24px;
         margin: 40px 0;
         color: #000;
     }

     .custom-button {
         background-color: rgba(217, 217, 217, 1);
         color: #000000;
         border-radius: 0px;
         padding: 10px 20px;
         margin-right: 10px;
         cursor: pointer;
         text-decoration: none;
         font-size: 16px;
         transition: background-color 0.3s;
     }

     .custom-button:hover {
         background-color: rgba(116, 20, 27, 1);
         color: white;
     }

     .form-buttons {
         display: flex;
         justify-content: space-between;
         margin-top: 30px;
     }

     .iti {
         width: 100%;
     }

     .image-bg-section-wrapper {
         position: relative;
         width: 100%;
         min-height: 400px;
         height: auto;
         overflow: hidden;
         display: flex;
         align-items: center;
         justify-content: center;
     }


     .bg-overlay-content {
         color: $white-200;
         text-align: center;
     }

     .bg-image-wrapper {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         z-index: -1;
         /* Place the image behind the content */
     }

     .bg-image {
         width: 100%;
         height: 100%;
         object-fit: cover;
         /* Cover the entire area */
         position: absolute;
         top: 0;
         left: 0;
     }

     .bg-image-overlay {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background-color: rgba(0, 0, 0, 0.4);
         /* Transparent overlay */
         z-index: 1;
         /* Position above image but below content */
     }

     .bcrum ul {
         display: flex;
         align-items: center;
         margin-bottom: 0px;
         gap: 15px;
     }

     .bcrum ul li a {
         color: var(--default-gray-2);
         text-decoration: none;
         font-family: var(--default-font);
     }

     .bcrum ul li a.active {
         color: $primary;
         text-decoration: none;
     }

     .bcrum ul svg {
         color: var(--default-gray-2);
     }

 }