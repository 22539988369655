@use "./custom" as *;

.page-not-found {
  width: 100vw;
  height: 75vh;
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;

  .page-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .error-code {
      text-align: center;
      font-family: "nb_international_probold";
      font-size: 96px;
      line-height: 110px;
    }

    .error-message {
      font-size: 14px;
      text-align: center;
    }

    .home-redirect {
      margin-top: 24px;
      text-decoration: none;

      .next-btn-1 {
        i {
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover {
          i {
            transform: translateY(-50%) translateX(-10px);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .page-not-found {
    .page-content {
      .error-code {
        font-size: 128px;
        line-height: 140px;
      }

      .error-message {
        font-size: 16px;
      }

      .home-redirect {
        margin-top: 48px;
      }
    }
  }
}