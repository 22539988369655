@use "../../custom" as *;

#initiatives-section {
  margin-bottom: 50px;
  .initiatives-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white-200;
    position: relative;
    overflow: hidden;
    height: 85vh;
    @media (max-width: 1024px) {
      height: 60vh;
    }
    @media (max-width: 576px) {
      height: 50vh;
    }

    .home-initiatives-slide {
      will-change: transform;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      overflow: hidden;

      .initiatives-body_image {
        backface-visibility: hidden;
        will-change: transform;
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }

    .initiatives-container {
      will-change: transform;
      padding-top: 50px;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      text-align: center;
      overflow: hidden;

      @media (max-width: 576px) {
        margin: 0px 0px;
        gap: 50px;
      }
      .initiative-content {
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
    

        .initiative-text {
          will-change:transform;
          width:50%;
          @media (max-width: 1024px) {
            width:80%;
          }
          h2 {
            margin-bottom: 24px;
            font-size: 36px;
            font-weight: 400;
            @media (max-width: 567px) {
              font-size: 24px;
            }
          }
          .initiatives-description {
            font-weight: 400;
            font-size: 18px;

            @media (max-width: 576px) {
              font-size: 16px;
            }
          }



        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    padding: min(1.5vw, 25px);
    border-radius: 5px;
  }
}
