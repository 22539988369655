@import "../../custom";

.portfolio-details-header-container {
    width: 100%;
    margin-top: $navbar-height;
}

.portfolio-details-header-wrapper {
    position: relative;
    width: 100%;
    height: 70vh;
    min-height: 300px;
    max-height: 700px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.portfolio-details-header-img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

@media screen and (min-width: 640px) {
    .portfolio-details-header-wrapper {
        min-height: 400px;
        height: 25vh;
    }
}

@media screen and (min-width: 1024px) {
    .portfolio-details-header-container {
        padding: 12px;
    }

    .portfolio-details-header-wrapper {
        border-radius: 3px;
    }
}