@use '../../custom' as *;

#proudctDetailsWrap {
   @media (max-width:576px){
    padding-left: 16px !important;
    padding-right: 16px !important;

   }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .font-size-36px {
    font-size: 36px !important;
  }

  .font-size-22px {
    font-size: 22px !important;
  }

  .font-size-18px {
    font-size: 18px !important;
  }

  .font-size-27px {
    font-size: 27px !important;
  }

  .default-gray {
    color: #818181;
  }

  .pb-120px {
    padding-bottom: 120px;
  }

  .p-48px {
    padding-bottom: 48px !important;
  }

  .mb-64px {
    margin-bottom: 64px
  }
  .mb-24px {
    margin-bottom: 24px;
  }

  .mt-64px {
    margin-top: 64px;
  }


  .product-details {
    &-wrapper {
      display: flex;
      gap: 64px;
    }
  }

  .preview {
    display: flex;
    flex-direction: column;

    img {
      border-radius: 4px;
    }
  }

  .preview-thumbnail.nav-tabs {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .preview-thumbnail.nav-tabs li {
    width: 31.06%;
    /* Distribute the width equally for three thumbnails */
  }

  .preview-thumbnail.nav-tabs li img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    object-position: left;
  }

  .preview .tab-content {
    overflow: hidden;
  }

  .preview .tab-content img {
    width: 100%;
    animation-name: opacity;
    animation-duration: 0.3s;
    height: 518px;
    object-position: left;
    object-fit: cover;
  }

  .preview-thumbnail .nav-link {
    padding: 0px !important;
  }

  .font-red {
    color: #A51D26;
  }

  .font-bold {
    font-weight: bold;
  }

  .pdetaillist {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 18px;
  }

  hr {
    border-color: #818181;
  }

  .product-info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    gap: 48px;
  }

  .product {
    display: flex;
    flex-direction: column;
    gap: 24px;

    p{
      margin: 0;
    }

    &-title {
      font-size: 36px;
    }

    &-desc {
      font-size: 22px;
    }

    &-subdesc {
      font-size: 18px;
      color: $black-300;
    }

    &-tags {
      font-size: 16px;
      list-style: none;
      display: flex;
      gap: 24px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      font-family: "nb_international_promono";
      li {
        color: $redcarpet-700;
      }
    }

    &-benefits {
      &--title {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 24px;
      }
      hr {
        margin: 0;
        margin-bottom: 24px;
      }
    }
  }



  @media (max-width:1200px) {
    .product-info-container {
      gap: 24px;
    }
    .product { 
      &-title {
        
        font-size: 24px;
      }

      &-desc {
        font-size: 18px;
      }

      &-subdesc {
        font-size: 16px;
      }

      &-tags {
        font-size: 14px;
      }

      &-benefits {
        &--title {
          font-size: 16px;
        }
      }

    }

    .pdetaillist {
      font-size: 16px;
    }
  }

  @media (max-width:992px) {
    .product {

      &-details-wrapper {
        flex-direction: column;
        gap: 40px;
        margin-top: 64px;
      }
    }
    .preview-thumbnail.nav-tabs li img {
      height: 100px;
    }

    .preview-thumbnail.nav-tabs li {
      width: 30.06%;
    }

    .preview .tab-content img {
      height: 226px;
    }
  }
}