@use "../../custom" as *;

.terms-condition-wrapper {
  margin-top: 64px;
  margin-bottom: 120px;

  @media (max-width: 567px) {
    margin-bottom: 184px;
  }

  .terms-conditions-section {
    margin-bottom: 64px;

    @media (max-width: 768px) {
      margin-bottom: 56px;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .content-wrapper {
      padding-left: 100px;
      padding-right: 100px;

      @media (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;
      }

      .section-title {
        margin-bottom: 12px;
        font-size: 27px;
        font-weight: 600;

        @media (max-width: 576px) {
          font-size: 18px;
        }
      }

      .text-content {
        font-size: 16px;
        font-weight: 400;
        @media (max-width: 576px) {
          font-size: 14px;
        }

        ul li::marker {
          font-size: 22px;
        }
      }
    }
  }
}
