@use '../../custom' as *;

#footer-2 {
  .footer {
    &-container {
      background-color: $black-400;
      color: $white-400;
      display: flex;
      align-items: flex-start;
      padding: 96px 118px;
      font-size: 14px;
      border: 20px solid $white-200;
    }

    &-grid-container {
      display: flex;
      align-items: flex-start;

      img {
        margin-right: 150px;
      }
    }

    &-logo {
      width: 133.17px;
      padding: 96px 0;
    }

    &-grid {
      padding-top: 96px;

      &-title {
        width: 100%;
        margin-bottom: 24px;
        color: $white-200;
        font-size: 16px !important;
      }
    }

    &-solutions,
    &-pages {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 48px;

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;

        a {
          text-decoration: none;
          transition: all 0.3s ease;
          padding-bottom: 5px;
          position: relative;
          display: inline-block;
        }
  
        a:hover {
          color: $white-200;
  
          &::after {
            content: '';
            position: absolute;
            background-color: $white-200;
            height: 1px;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
    }

    &-pages {
      li {
        color: $white-200;
      }
    }

    &-members {
      width: 100%;
      padding-top: 48px;
      display: flex;
      flex-direction: column;
      gap: 18px;

      .card-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
      }

      .card {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 102px;
        padding: 10px;

        .logo {
          margin: auto;
          width: 80px;
        }
      }

      h6 {
        margin: 0;
        color: $white-200;
      }
    }

    &-end {
      width: 100%;
      display: flex;
      padding-top: 64px;
      gap: 99px;
      justify-content: flex-start;
      align-items: center;

      .footer-copyright {
        display: flex;
        gap: 10px;
        text-transform: uppercase;
        align-items: center;
        letter-spacing: 3px;

        h6 {
          margin: 0;
          font-size: inherit;
        }

        a {
          text-decoration: underline;
        }
      }

      .footer-socials {
        align-self: center;
        font-size: 20px !important;
        display: flex;
        align-items: center;
        gap: 32px;

        i {
          font-size: inherit;
          transition: color 0.3s ease, transform 0.3s ease;
        }
        
        svg {
          width: 21px; 
          height: 19px; 
          transition: fill 0.3s ease, transform 0.3s ease; 
          vertical-align: middle;
          margin-top: -5px;
        }
        
        i:hover,
        svg:hover path {
          fill: $white-200; 
          color: $white-200; 
          transform: scale(1.1); 
        }
        

        a {
          margin: 0;
          padding: 0;
          display: inline-block;
          
        }

        i:hover,
        svg:hover path {
          fill: $white-200; /* Change the fill color of the SVG path */
          transform: scale(1.1); /* Slightly enlarge the icon on hover */
        }
        
        i:hover {
          color: $white-200; /* Change the color of the <i> element on hover */
          transform: scale(1.1); /* Slightly enlarge the icon on hover */
        }
        
      }

      @media (max-width: 1200px) {
        flex-direction: column-reverse;
        gap: 20px;

        .footer-logos {
          align-self: center;
        }

        .footer-copyright {
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
        }
      }
    }

    @media (max-width: 1200px) {
      &-container {
        padding: 64px 16px;
        border: none;
      }

      &-grid-container {
        flex-direction: column;
        align-items: center;

        img {
          margin-right: 0;
        }
      }

      &-grid {
        padding-top: 56px;
      }

      &-logo {
        padding: 0;
      }

      &-solutions,
      &-pages {
        width: 100%;
      }

      &-members {
        padding-bottom: 56px;
      }

      &-end {
        align-items: flex-start;
      }
    }

    @media (max-width: 576px) {
      &-container {
        font-size: 12px !important;
      }

      &-grid-title {
        font-size: 14px !important;
      }
    }
  }
}
