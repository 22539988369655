@use "../../custom" as *;

#statistics-counter {
  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .content-row {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      margin-left: 0;
      margin-right: 0;
      width: 100%;
      .content-col {
        border-radius: 3px;
        border: 1px solid $white-300;
        height: 250px;
        text-align: center;
        color: $black-400;
        padding: 16px;

        @media (max-width: 767px) {
          height: 160px;
        }

        .content-stats {
          display: flex;
          margin-bottom: 20px;
          font-weight: 400;
          font-family: "nb_international_promono";
          font-size: 42px;
          @media (max-width: 767px) {
            font-size: 21px;
          }
        }

        .content-text {
          font-size: 18px;
          font-weight: bold;
          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .statistics--title {
    margin-top: 96px;
    margin-bottom: 48px;
  }
}
