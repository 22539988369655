@use "../custom" as *;

#btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .button-container {
        &-btn {
            display: flex;
            text-decoration: none;
            justify-content: center;
            align-items: center;
            gap: 5px;
            cursor: pointer;
            color: $black-400;
            font-weight: 300;
            transition: transform 0.6s ease, color 0.3s ease-in-out;

            &_text {
                transition: all 0.6s ease;
                font-size: 14px;
            }

            &_icon {
                height: 32px;
                width: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.6s ease;

                i {
                    font-size: 14px;
                }
            }

            &:hover {
                color: $redcarpet-700;
                transform: scale(1.02);

                .button-container-btn_text {
                    transform: translateX(-3px);
                }

                .button-container-btn_icon {
                    background-color: $primary;
                    color: $white-200;
                    transform: translateX(3px);
                }
            }

            @media (max-width: 768px){
                color: $redcarpet-700;
                transform: scale(1.02);
                .button-container-btn_text {
                    transform: translateX(-3px);
                }

                .button-container-btn_icon {
                    background-color: $primary;
                    color: $white-200;
                    transform: translateX(3px);
                }
            }
        }

        &-tag {
            text-decoration: none;
            color: $black-400;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border: 1px solid $white-300;
            border-radius: 4px;
            padding: 12px 24px;
            transition: all 0.3s ease;
            position: relative;
            cursor: pointer;
            font-weight: 300;

            &_icon {
                position: absolute;
                transform: translate(-13px);
                opacity: 0;
                right: 0;
                color: $white-200;
                transition: all 0.3s ease;
                font-size: 14px;
            }

            &_text {
                font-size: 14px;
            }

            &:hover {
                background-color: $primary;
                padding-right: 35px;

                .button-container-tag_text {
                    color: $white-200;
                }

                .button-container-tag_icon {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    @media (max-width: 767.98px) {
        flex-direction: column;

        .button-container {
            width: 90%;

            &-btn {
                &_text {
                    font-size: 12px;
                }

                &_icon {
                    i {
                        font-size: 12px;
                    }
                }
            }

            &-tag {
                padding: 16px 24px;
            }
        }
    }

    .invert-arrow-button {

        .button-container-btn_text,
        .fa-arrow-right {
            color: $white-200;
        }

        &:hover {
            .button-container-btn_text {
                color: $primary;
            }
        }
    }
}

.gray-btn-1 {
    text-decoration: none;
    background-color: $white-300;
    padding: 12px;
    color: $black-400;
    border-radius: 2px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    font-size: 11px;
    border: none;
    position: relative;
    transition: all 0.3s ease;
    font-family: "nb_international_proregular";

    .fa-arrow-right {
        position: absolute;
        opacity: 0;
        right: 0;
        transition: all 0.3s ease;
        color: $white-200;
    }

    &:hover {
        padding-right: 50px;
        background-color: $black-400;
        color: $white-200;

        .fa-arrow-right {
            opacity: 1;
            transform: translate(-15px);
        }
    }

    @media (max-width: 640px) {
        font-size: 10px;
    }
}

.back-btn-1 {
    text-decoration: none;
    border: none;
    font-size: 12px !important;
    border-radius: 2px;
    padding: 14px;
    background-color: $white-300;
    position: relative;
    transition: all 0.3s ease;
    color: $black-400;

    i {
        position: absolute;
        left: 0;
        opacity: 0;
        transition: all 0.3s ease;
        color: $white-200;
    }

    &:hover {
        background-color: $black-400;
        color: $white-200;
        padding-left: 35px;

        i {
            opacity: 1;
            transform: translate(15px);
        }
    }
}

.next-btn-1 {
    text-decoration: none;
    border: none;
    font-size: 12px !important;
    border-radius: 2px;
    padding: 14px;
    background-color: $redcarpet-700;
    position: relative;
    transition: all 0.3s ease;
    color: #fffffe;

    i {
        position: absolute;
        right: 0;
        opacity: 0;
        transition: all 0.3s ease;
    }

    &:hover {
        background-color: $black-400;
        color: $white-200;
        padding-right: 35px;

        i {
            opacity: 1;
            transform: translate(-15px);
        }
    }
}

button.load-more-ajax-btn:disabled {
    background-color: $white-300;
    color: $black-300;
    cursor: not-allowed;

    .fa-arrow-right {
        display: none;
        opacity: 0;
    }

    &:hover {
        padding: 12px;
        .fa-arrow-right {
            display: none;
            transform: translate(0);
        }
    }
}