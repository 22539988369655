@import "@../../../../custom";

.portfolio-solutions-implemented-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 550px;
    min-width: 320px;
    overflow: hidden;

    .solutions-implemented-bg-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;
        padding: inherit;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background: linear-gradient(180deg,
                    rgba(0, 0, 0, 0) 35.78%,
                    rgba(0, 0, 0, 0.8) 100%),
                linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
        }
    }
}

.portfolio-solutions-implemented-wrapper {
    h1 {
        text-align: center;
        font-size: 24px;
        color: #fffffe;
        font-weight: 400;
    }
}

.OurCapabilitiesCard.card {
    position: relative;
    height: 65vh;
    min-height: 355px;
    min-width: 280px;
    border: 0px;
    border-radius: 4px;
    background: transparent;

    /* Hide scrollbar for webkit browsers (Chrome, Safari) */
    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    /* For Firefox */
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */

    /* For Internet Explorer and Edge */
    -ms-overflow-style: none;
    /* Hide scrollbar in IE and Edge */

    .OurCapabilitiesCardBg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background: rgba(222, 218, 218, 0.08);
        backdrop-filter: blur(16px);
        z-index: -1;
    }
}

.OurCapabilitiesCard.card ul li {
    padding: 10px;
    text-align: end;
}

.OurCapabilitiesCard.card ul li a {
    text-decoration: none;
    color: $white-400;
}

.OurCapabilitiesCard.card ul li.active {
    border-right: 3px solid $primary;
}

.OurCapabilitiesCard.card ul li.active a {
    text-decoration: none;
    color: #fffffe;
}

.OurCapabilitiesCard .right-section {
    overflow-y: hidden;
    overflow-x: hidden;

    .right-wrapper {
        width: 100%;
        height: 100%;

        .adjust-width {
            width: 90%;
        }

        .img-min-h {
            min-height: 150px;
        }

        .con-min-h {
            min-height: 300px;
        }
    }
}

.OurCapabilitiesCard .right {
    min-height: 245px;
    max-height: calc(65vh - 110px);
    //overflow-y: scroll;
    scrollbar-width: none;

    .background {
        background-color: $white-200;
    }
}

.OurCapabilitiesCard .left-section-links {
    font-size: 14px;
}

.OurCapabilitiesCard .right-scroll-section-content {
    h1 {
        font-size: 18px;
        color: $cooldecember-100;
        font-weight: 400;
    }

    .right-scroll-section-content-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    h2.content-head {
        font-size: 18px;
        color: $thamarblack-400;
        font-family: "nb_international_proregular";
        text-align: center;
    }

    .right-scroll-section-content-text {
        font-size: 14px;
        color: $thamarblack-400;

        .fw-bold {
            font-weight: 400;
        }

        .fw-light {
            font-weight: 300;
            color: $thamarblack-300;
        }
    }
}

.OurCapabilitiesCard .right-scroll-section-content {
    h1 {
        text-align: start;
        margin-left: 10px;
    }
}

.OurCapabilitiesCard.card ul li.active {
    border-right: none;
}

.OurCapabilitiesCard.card ul li.active {
    border-bottom: 3px solid $primary;
    margin-bottom: 0px;
}

.clisttitle {
    border-bottom: 1px solid $thamarblack-300;
}

@media screen and (min-width: 576px) {
    .portfolio-solutions-implemented-wrapper {
        h1 {
            font-size: 26px;
        }
    }

    .OurCapabilitiesCard .left-section-links {
        font-size: 16px;
    }

    .OurCapabilitiesCard .right-section {
        .right-wrapper {

            .img-min-h,
            .con-min-h {
                min-height: 325px;
            }
        }
    }

    .OurCapabilitiesCard .right-scroll-section-content {
        h1 {
            font-size: 20px;
        }

        h2.content-head {
            font-size: 20px;
        }

        .right-scroll-section-content-text {
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 767.5px) {
    .portfolio-solutions-implemented-container {
        padding: 0px;

        .solutions-implemented-bg-wrapper {
            padding: 0px;
        }
    }

    .portfolio-solutions-implemented-container.container-fluid {
        padding: 0px;
    }

    .OurCapabilitiesCard .left-section-links {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }
}

@media screen and (min-width: 768px) {
    .portfolio-solutions-implemented-container {
        height: calc(100vh - $scroll-update-container-height);
    }

    .portfolio-solutions-implemented-wrapper {
        h1 {
            font-size: 28px;
            margin-bottom: 1rem;
        }
    }

    .OurCapabilitiesCard .card-body {
        padding: 24px;
    }

    .OurCapabilitiesCard.card ul li {
        padding: 15px;
    }

    .OurCapabilitiesCard .right {
        max-height: calc(65vh - 48px);
        padding-left: 30px;
        padding-top: 15px;
        padding-right: 30px;
        padding-bottom: 15px;
    }

    .OurCapabilitiesCard .right-section {
        overflow-y: hidden;
        overflow-x: hidden;

        .right-wrapper {
            width: 100%;
            height: 100%;

            .adjust-width {
                width: 100%;
            }

            .img-min-h,
            .con-min-h {
                min-height: 325px;
            }
        }
    }

    .OurCapabilitiesCard .right-scroll-section-content {
        h1 {
            margin-left: 0px;
        }
    }

    .OurCapabilitiesCard.card {
        .left-section {
            border-right: 1px solid $thamarblack-300;
            padding-right: 0px;
            max-height: calc(65vh - 48px);
            overflow-y: scroll;
            overflow-x: hidden;
        }
    }

    .OurCapabilitiesCard.card ul li.active {
        border-bottom: none;
        margin-bottom: 0px;
        border-right: 3px solid $primary;
        margin-right: -1px;
    }

    .clisttitle {
        border-bottom: none;
    }
}

@media screen and (min-width: 992px) {
    .portfolio-solutions-implemented-wrapper {
        h1 {
            font-size: 32px;
            margin-bottom: 2rem;
        }
    }

    .OurCapabilitiesCard .left-section-links {
        font-size: 18px;
    }

    .OurCapabilitiesCard.card ul li {
        padding: 20px;
    }

    .OurCapabilitiesCard .right-scroll-section-content {
        h1 {
            font-size: 22px;
        }

        h2.content-head {
            font-size: 22px;
        }

        .right-scroll-section-content-text {
            font-size: 14px;
        }
    }
}

@media screen and (min-width: 1400px) {
    .portfolio-solutions-implemented-wrapper {
        h1 {
            font-size: 34px;
            margin-bottom: 2.5rem;
        }
    }

    .OurCapabilitiesCard .left-section-links {
        font-size: 20px;
    }

    .OurCapabilitiesCard .right-scroll-section-content {
        h1 {
            font-size: 24px;
        }

        h2.content-head {
            font-size: 24px;
        }

        .right-scroll-section-content-text {
            font-size: 16px;
        }
    }
}