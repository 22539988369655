@use "../../custom" as *;

.sustainability-section {
    @media (max-width: 576px) {
        margin-top: 64px;
    }

    /* text color */
    .custom-text-secondary {
        color: $black-300;
    }

    .custom-text-black {
        color: $black-400;
    }

    .custom-text-red {
        color: $primary;
    }

    /* font size */
    .custom-fs-1 {
        font-size: 36px;
        line-height: 44px;
    }

    .custom-fs-2 {
        font-size: 27px;
    }

    .custom-fs-3 {
        font-size: 22px;
        line-height: 30px;
    }

    .custom-fs-4 {
        font-size: 18px;
        line-height: 26px;
    }

    .custom-fs-5 {
        font-size: 16px;
    }

    @media (max-width: 768px) {
        .custom-fs-1 {
            font-size: 24px;
        }

        .custom-fs-3 {
            font-size: 18px;
        }

        .custom-fs-4 {
            font-size: 16px;
        }
    }

    /* padding 120px */
    .p-120px {
        padding: 120px;
    }

    .pt-120px {
        padding-top: 120px;
    }

    .pb-120px {
        padding-bottom: 120px;
    }

    .ps-120px {
        padding-left: 120px;
    }

    .pe-120px {
        padding-right: 120px;
    }

    .px-120px {
        padding-left: 120px;
        padding-right: 120px;
    }

    .py-120px {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    /* padding 64px */
    .p-64px {
        padding: 64px;
    }

    .pt-64px {
        padding-top: 64px;
    }

    .pb-64px {
        padding-bottom: 64px;
    }

    .ps-64px {
        padding-left: 64px;
    }

    .pe-64px {
        padding-right: 64px;
    }

    .px-64px {
        padding-left: 64px;
        padding-right: 64px;
    }

    .py-64px {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    /* margin 120px */
    .m-120px {
        margin: 120px;
    }

    .mt-120px {
        margin-top: 120px;
    }

    .mb-120px {
        margin-bottom: 120px;
    }

    .ms-120px {
        margin-left: 120px;
    }

    .me-120px {
        margin-right: 120px;
    }

    .mx-120px {
        margin-left: 120px;
        margin-right: 120px;
    }

    .my-120px {
        margin-top: 120px;
        margin-bottom: 120px;
    }

    /* margin 64px */
    .m-64px {
        margin: 64px;
    }

    .mt-64px {
        margin-top: 64px;
    }

    .mb-md-56px {
        @media (max-width: 768px) {
            margin-bottom: 56px;
        }
    }

    .mb-64px {
        margin-bottom: 64px;
    }

    .mb-24px {
        margin-bottom: 24px;
    }

    .ms-64px {
        margin-left: 64px;
    }

    .me-64px {
        margin-right: 64px;
    }

    .mx-64px {
        margin-left: 64px;
        margin-right: 64px;
    }

    .my-64px {
        margin-top: 64px;
        margin-bottom: 64px;
    }

    /* line height */
    .lh-44px {
        line-height: 44px;
    }

    .lh-30px {
        line-height: 30px;
    }

    .lh-26px {
        line-height: 26px;
    }

    /* font weight */
    .fw-500 {
        font-weight: 500;
    }

    .sus-header {
        display: flex;
        gap: 64px;
        margin-bottom: 120px;
    }

    .sus-header-content {
        display: flex;
        flex-direction: column;
        gap: 48px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
    }

    .sus-section-2 {
        margin-top: 160px;
        margin-bottom: 120px;
    }

    .sus-header-image {
        display: flex;
        justify-content: start;
        align-items: start;
        height: 450px;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 4px;
        }
    }

    .custim-mw-50 {
        max-width: 50%;
    }

    @media (max-width: 992px) {
        .custim-mw-50 {
            max-width: 100%;
        }

        .sus-header {
            flex-direction: column;
            gap: 46px;
        }

        .sus-header-content {
            gap: 24px;
        }

        .sus-header-image {
            height: 350px;
        }

        .sus-section-2 {
            margin-top: 96px;
            margin-bottom: 96px;
            flex-direction: column-reverse;
        }
    }

    @media (max-width: 768px) {
        .sus-header-image {
            height: 300px;
        }
    }

    @media (max-width: 576px) {
        .sus-header-image {
            height: 250px;
        }
    }

    .card {
        transition: all 0.3s ease;
    }

    .card-img-container {
        height: 200px !important;
        width: 100%;

        .card-img-top {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .sus-card-link {
        color: $black-400;
        text-decoration: none;
        cursor: pointer;
    }

    .esg {
        &.card-body {
            padding: 24px;
        }

        &-title {
            font-size: 22px;
            margin-bottom: 12px;
            font-family: "nb_international_proregular";
        }

        &-list {
            ul {
                display: flex;
                flex-direction: column;
                gap: 8px;
                font-size: 18px;
                padding-left: 20px;
            }
        }

        @media (max-width: 768px) {
            &-title {
                font-size: 16px;
            }

            &-list {
                font-size: 16px;
                padding-left: 15px;
            }
        }
    }

    .esg-custom-margin {
        &-desc {
            margin-bottom: 64px;
        }

        &-title {
            margin-bottom: 24px;
        }

        @media (max-width: 768px) {
            &-desc {
                margin-bottom: 40px;
            }
        }
    }

    .related-slider {
        &-top-margin {
            margin-top: 5rem;
        }

        &_item {
            text-decoration: none;
            color: inherit;
            display: flex;
            align-items: center;
            border: 2px solid $white-300;
            border-radius: 5px;
            min-height: 295px;
            transition: all 0.3s ease;
            padding: 24px;
            margin-bottom: 10px;
            height: 302px;
            gap: 24px;
            user-select: none;

            .image-container {
                height: 254px;
                overflow: hidden;
                min-width: 40%;

                img {
                    width: 100%;
                    height: 100%;
                    transition: transform 0.3s ease;
                    object-fit: cover;
                    border-radius: 2px;
                }
            }

            @media (max-width: 768px) {
                flex-direction: column;
                height: 463px;

                .image-container {
                    height: 247px;
                    width: 100%;
                }
            }
        }

        &_info {
            font-family: "nb_international_promono";
            opacity: 0;
            visibility: hidden;
            display: flex;
            justify-content: space-between;
            margin: 0px 10px;
            transition: all 0.3s ease;
            font-size: 14px;

            &--date {
                word-spacing: 10px;
                width: 100%;
                color: $black-300;
                font-size: small;
                text-align: end;
                font-size: inherit;
            }

            &--title {
                width: 100%;
                font-size: small;
                text-transform: uppercase;
                color: $redcarpet-700;
                font-size: inherit;
            }
        }

        &:hover {
            .related-slider_info {
                opacity: 1;
                visibility: visible;

            }

            .related-slider_item {
                background-color: $white-300;
            }

            .related-slider_item--image {
                transform: scale(1.1);
            }
        }
    }

    .info-container {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        &--title {
            margin-bottom: 24px;

            h5 {
                font-size: 14px;
                font-weight: bold;
                margin: 0;
            }
        }

        &--desc {
            font-weight: 300;
            color: $black-400;

            p {
                margin: 0;
                font-size: 12px;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        &--link {
            color: $black-400;
            cursor: pointer;
            font-size: 12px;

            span {
                transition: color 0.3s ease;

                .fa-arrow-right {
                    opacity: 0;
                    color: $white-200;
                    background-color: $primary;
                    padding: 10px;
                    border-radius: 2px;
                    transition: opacity 0.3s ease;
                    margin-left: 8px;
                }
            }

            span:hover {
                color: $primary !important;

                .fa-arrow-right {
                    opacity: 1;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .info-container {
            height: 100%;
            flex: auto;

            &--title {
                h5 {
                    font-size: 18px;
                }

            }

            &--desc {
                p {
                    font-size: 14px;
                }
            }

            &--link {
                font-size: 14px;
            }
        }

        .image-container {
            width: 250px;
        }
    }

    .mb-150px {
        margin-bottom: 150px;
    }

    .imgtext {
        display: flex;
        gap: 64px;
        margin-bottom: 120px;

        &-image {
            width: 100%;
            height: 0px;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            gap: 48px;
            width: 100%;


            &_title {
                font-size: 36px;
            }

            &_desc {
                display: flex;
                flex-direction: column;
                gap: 24px;

                &--main {
                    font-size: 22px;
                    line-height: 30px;
                }

                &--sub {
                    font-size: 18px;
                    color: $black-300;
                    line-height: 26px;
                }
            }
        }
    }

}