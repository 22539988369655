@import "../custom";

.card.card-with-details {
    text-decoration:none;
    height: 350px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $white-300;
    padding: 48px;
    overflow: hidden;
    transition: all ease 0.5s;

    &:hover {
        box-shadow: 121px 146px 53px 0px rgba(0, 0, 0, 0),
            77px 93px 49px 0px rgba(0, 0, 0, 0.01),
            44px 53px 41px 0px rgba(0, 0, 0, 0.05),
            19px 23px 30px 0px rgba(0, 0, 0, 0.09),
            5px 6px 17px 0px rgba(0, 0, 0, 0.1);

        // enable learn more button only for desktop
        @media screen and (min-width: 1024px) {

            .card-hover-button-wrapper {
                opacity: 1;
                width: 100%;
                transform: translateY(0px);
            }

            .card-with-details-content {
                gap: 10px;
                padding-bottom: 70px;
            }
        }
    }
}

.card-with-details-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 10px;
    padding: 0 !important;
    position: relative;

    .hide-card-title {
        display: none;
    }
}

.card-with-details-content {
    display: flex;
    flex-direction: column;
    gap: 48px;
    transition: all ease 0.5s;
}

.card-with-details-image {
    width: 100%;
    height: 184px;

    .card-img-top {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.card-with-details-title {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: $redcarpet-700;
}

.card-hover-button-wrapper {
    display: none;
    width: 50%;
    opacity: 0;
    transition: all ease 0.5s;
    position: absolute;
    bottom: 0;
    transform: translateY(100px);

    .card-hover-button {
        width: 100%;
        text-decoration: none;
        border: none;
        padding: 12px;
        background-color: $primary;
        color: $white-200;
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
    }
}

@media screen and (min-width: 640px) {
    .card-with-details-title {
        font-size: 14px;
    }
}

// enable learn more button only for desktop
@media screen and (min-width: 1024px) {
    .card-hover-button-wrapper{
        display: block;
    }
}