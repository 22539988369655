@use "../../custom" as *;

#news-page-container {
  margin-top: 120px;
  margin-bottom: 120px;
  overflow: hidden;
  

  @media (max-width: 768px) {
    .custom-swiper-wrapper {
      margin-left: 0 !important;
    }

    .swiper {
      overflow: hidden !important;
    }
  }

  .news-page--title {
    margin: 96px 0 48px 0;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      margin: 64px 0 40px 0;

    }

    h2 {
      max-width: 50%;
      font-size: 36px;
    }

    @media (max-width: 768px) {
      h2 {
        max-width: 80%;
        font-size: 24px;
      }
    }
  }

  .custom-swiper-wrapper {
    margin-left: 100px;
  }

  .swiper {
    overflow: visible;
  }

  .news-header {
    display: flex;
    justify-content: space-between;

    h6,
    &_page {
      font-size: small;
      font-weight: 400;
      color: $black-300;
    }

    &_page {
      word-spacing: 10px;
    }
  }

  .news-slider {
    &-top-margin {
      margin-top: 5rem;
    }

    &_item {
      text-decoration: none;
      color: inherit;
      display: flex;
      align-items: center;
      border: 2px solid $white-300;
      border-radius: 5px;
      min-height: 295px;
      transition: all 0.3s ease;
      padding: 24px;
      margin-bottom: 10px;
      height: 295px;
      gap: 20px;
      user-select: none;
      margin: 10px;
      height: 302px;

      &--image {
        width: 100%;
        height: 100%;
        transition: transform 0.3s ease;
        object-fit: cover;
      }
    }

    &_info {
      opacity: 0;
      visibility: hidden;
      display: flex;
      justify-content: space-between;
      margin: 0px 25px;
      transition: all 0.3s ease;
      pointer-events: none;
      font-family: "nb_international_promono";

      &--date {
        width: 100%;
        color: $black-300;
        font-size: small;
        text-align: end;
      }

      &--title {
        width: 100%;
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    &:hover {
      .news-slider_info {
        opacity: 1;
        visibility: visible;

        &--title {
          color: $redcarpet-700;
        }
      }

      .news-slider_item {
        background-color: $black-100;

        .news-slider_item--image {
          transform: scale(1.1);
        }
      }
    }
  }

  .image-container {
    overflow: hidden;
    min-width: 193px;
    height: 100%;

    @media (max-width: 768px) {
      min-width: 148px;
    }
  }

  .info-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--desc {
      p {
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    &--title {
      font-size: 14px;
      font-weight: 600;

      h5 {
        font-size: inherit;
        font-weight: inherit;
      }
    }

    &--heading {
      font-size: 12px;
      color: $black-300;
      margin-bottom: 12px;
      text-transform: uppercase;
      font-family: "nb_international_promono";
    }

    &--link {
      font-size: 12px;

      span {
        transition: color 0.3s ease;
        align-self: flex-start;

        .fa-arrow-right {
          opacity: 0;
          color: $white-200;
          background-color: $primary;
          padding: 10px;
          border-radius: 2px;
          transition: opacity 0.3s ease;
          margin-left: 8px;
        }

        &:hover {
          color: $primary !important;

          .fa-arrow-right {
            opacity: 1;
          }
        }
      }
    }
  }

  .news-content-slider {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 295px;
    padding: 24px 0px;
    font-size: 12px;

    &--heading {
      color: $black-300;
      text-transform: uppercase;
      font-size: 12px;
      margin-bottom: 12px;
      font-family: "nb_international_promono";
    }

    &--title {
      font-size: 14px;
      font-weight: 600;
      max-width: 60%;
    }

    &--desc {
      p {
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    span {
      transition: color 0.3s ease;
      align-self: flex-start;

      .fa-arrow-right {
        opacity: 0;
        color: $white-200;
        background-color: $primary;
        padding: 10px;
        border-radius: 2px;
        transition: opacity 0.3s ease;
        margin-left: 8px;
      }

      &:hover {
        color: $primary !important;

        .fa-arrow-right {
          opacity: 1;
        }
      }
    }

    a {
      transition: color 0.3s ease;
      align-self: flex-start;
      font-size: 12px;

      .fa-arrow-right {
        opacity: 0;
        color: $white-200;
        background-color: $primary;
        padding: 10px;
        border-radius: 2px;
        transition: opacity 0.3s ease;
        margin-left: 8px;
      }

      &:hover {
        color: $primary !important;

        .fa-arrow-right {
          opacity: 1;
        }
      }
    }
  }

  .news-section-container {
    margin-top: 96px;

    @media (max-width: 768px) {
      margin-top: 64px;
    }

  }

  @media (max-width: 993px) {
    .news-slider_item {
      padding: 16px;

      .news-content-slider {
        padding: 20px 0;
      }

      .image-container {
        width: 148px;
        height: 263px;
      }
    }

    .news-content-slider,
    .info-container {
      &--title {
        font-size: 14px;
      }

      &--heading {
        font-size: 12px !important;
      }

      &--desc {
        font-size: 12px;
      }

      span {
        transition: color 0.3s ease;
        align-self: flex-start;

        .fa-arrow-right {
          opacity: 0;
          color: $white-200;
          background-color: $primary;
          padding: 10px;
          border-radius: 2px;
          transition: opacity 0.3s ease;
          margin-left: 8px;
        }

        &:hover {
          color: $primary !important;

          .fa-arrow-right {
            opacity: 1;
          }
        }
      }
    }
  }
}
