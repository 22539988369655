//Variables used here are defined in the _custom.scss file.
@use "../../custom" as *;

.header-with-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $navbar-z-index;
}

.navbar-container {
  position: relative;
  z-index: inherit;
  height: $navbar-height;

  .navbar-data {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $nav-menu-item-fs;
  }

  &:hover .navbar-hover-effect {
    top: 0;
  }
}

.navbar-logo {
  position: absolute;
  left: 35px;
  top: translateX(-50%);

  img {
    mix-blend-mode: difference;
  }

  //background-image: url('data:image/svg+xml,<svg width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.0729 0H0.501953V39.8677H7.07378V6.64611H27.0729V0Z" fill="%23000000"/><path d="M26.8029 13.2922H13.6504V19.9383H26.8029V13.2922Z" fill="%23000000"/><path d="M26.8029 26.5803H13.6504V33.2264H26.8029V26.5803Z" fill="%23000000"/></svg>');
  //background-image: url('data:image/svg+xml,<svg width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.0729 0H0.501953V39.8677H7.07378V6.64611H27.0729V0Z" fill="%23B8202A"/><path d="M26.8029 13.2922H13.6504V19.9383H26.8029V13.2922Z" fill="%23B8202A"/><path d="M26.8029 26.5803H13.6504V33.2264H26.8029V26.5803Z" fill="%23B8202A"/></svg>');
  /* background-attachment: fixed | scroll; */
  //background-size: contain;
  /* background-position: center | right 30px bottom 15px; */
  //background-repeat: no-repeat;
  /* background-origin: border-box | padding-box | content-box; */
  /* background-clip: border-box | padding-box | content-box; */
  //background-blend-mode: difference;
  //mix-blend-mode: exclusion;
}

.navbar-actions {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  column-gap: 2rem;
  font-size: $nav-menu-action-fs;
  cursor: pointer;

  img.navbar-action-icon {
    mix-blend-mode: difference;
    height: 16px;
    width: 16px;
    object-fit: contain;
  }

  .search-action {
    position: relative;

    .nav_search_action_icon {
      //position: absolute;
      top: 0;
      right: 0;
    }

    .nav-search-action-form {
      display: none;

      &.show-search-action-form {
        display: block;
      }

      .live-search-results-container {
        position: absolute;
        top: 50px;
        left: 0;
        transform: translateX(-80%);
        width: 330px;
        //height: 300px;
        max-height: 400px;
        padding: 0.75rem;
        background-color: #fffffe;
        border-radius: 5px;

        overflow-y: scroll;

        ::-webkit-scrollbar {
          width: 0;
          height: 0;
        }

        /* For Firefox */
        scrollbar-width: none;
        /* Hide scrollbar in Firefox */

        /* For Internet Explorer and Edge */
        -ms-overflow-style: none;
        /* Hide scrollbar in IE and Edge */

        .container-header {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: start;
          color: $black-400;
          justify-content: center;
          font-family: "nb_international_proregular";
          font-size: 18px;
          border-bottom: 2px solid $white-400;
          cursor: default;
        }

        .container-content {
          min-height: min-content;
          display: flex;
          flex-direction: column;
          font-size: 16px;
          font-family: "nb_international_prolight";
          color: $black-400;

          .result-item-link {
            max-width: 100%;
            text-decoration: none;
            color: $black-400;
            padding: 1rem 0.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;

            .result-item {
              height: 40px;

              .highlight-keyword {
                color: $redcarpet-800;
                font-family: "nb_international_proregular";
                font-size: 18px;
              }
            }
          }
        }

        .container-footer {
          .see-all-res-button {
            display: none;
            background-color: transparent;
            border: none;
            padding: 0.5rem;
            text-decoration: none;
            color: $black-400;

            &:hover,
            &:active,
            &:focus {
              outline: none;
              box-shadow: none;
            }

            .see-all-res-button-container {
              display: flex;
              align-items: center;
              column-gap: 1rem;
              font-size: 14px;

              img {
                height: 21px;
                width: 16px;
                object-fit: contain;
              }
            }
          }

          .no-results {
            display: none;
            width: 100%;
            height: 100%;
            padding: 1rem;
            text-align: center;
          }
        }
      }
    }

    .nav-search-form-input {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%) translateX(8px);
      height: 40px;
      width: max(135px, 13vw);
      min-width: 135px;
      max-width: 250px;
      background-color: transparent;
      padding-left: 5px;
      padding-right: 25px;
      border: 1px solid $black-300;
      border-radius: 5px;
      font-size: 14px;

      &:focus {
        outline: none;
        border: 1px solid $black-300;
      }
    }

    .search-form-button-with-icon {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      height: 16px;
      width: 16px;
      padding: 0;
      font-size: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .nav_language_toggle_icon {
    //filter: brightness(0) saturate(100%) invert(8%) sepia(6%) saturate(2000%) hue-rotate(360deg) brightness(95%) contrast(100%);

    &.lang-tog-menu-open {
      filter: brightness(0) saturate(100%) invert(26%) sepia(94%) saturate(358%) hue-rotate(-19deg) brightness(90%) contrast(98%);
    }
  }

  .language-change {
    position: relative;

    ul,
    li {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .language-change-menu {
      position: absolute;
      top: 35px;
      right: -30px;
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      z-index: calc($navbar-z-index + 1);
      width: 305px;
      height: 140px;
      padding: 1rem;
      background-color: #fffffe;
      border-radius: 5px;

      &::before {
        content: "";
        position: absolute;
        top: -5px;
        right: 28px;
        width: 20px;
        height: 20px;
        background-color: #fffffe;
        transform: rotate(45deg);
        z-index: -1;
      }

      .menu-item-container {
        width: 100%;
        height: 50px;
        border: 1px solid $black-200;
        border-radius: 5px;

        &.active {
          border: 0.5px solid $primary;
        }

        a {
          text-decoration: none;
        }

        .menu-item {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0.5rem;
          column-gap: 1rem;

          img {
            border-radius: 50%;
            width: 30px;
            height: 30px;
            object-fit: cover;
          }

          span {
            font-size: 18px;
            font-weight: 300;
          }
        }
      }

      &.show-language-change-menu {
        display: flex;
      }
    }
  }
}

.navbar-menu-list {
  display: flex;
  justify-content: center;
  align-items: center;
  //row-gap: 1.5rem;
  column-gap: 1rem;

  .navbar-menu-list-item {
    //padding: 0.65rem 0.75rem;
    border-radius: 2px;
    cursor: pointer;
    font-family: "nb_international_promono";
    font-size: 14px;
    text-transform: uppercase;
    color: $black-400;

    &:hover {
      background-color: $primary;
      color: $white-200;
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }

    a {
      display: inline-block;
      text-decoration: none;
      padding: 0.65rem 0.75rem;
      border-radius: 2px;
    }
  }

  .navbar-menu-item_solutions.hovered-solutions :hover {
    background-color: $black-400;
    color: $white-200;
  }

  //Adds a class "hovered solutions" to track the hover effect and apply the desired animation of the SOLUTIONS item staying hovered when the SOLUTIONS menu is expanded.
  .navbar-menu-item_solutions.hovered-solutions {
    background-color: $black-400;
    color: $white-200;
  }
}

.navbar-menu-list-item.nav-item-active,
.navbar-menu-list-item.nav-item-active:hover {
  background-color: $black-400;
  color: $white-200;
}

.navbar-item-solutions-expand {
  display: none;
  position: fixed;
  top: $navbar-height;
  left: 0;
  z-index: $navbar-z-index;
  height: 460px;
  width: 100%;
  background-color: $redcarpet-100;
  border-top: 1px solid $white-300;
  transition: opacity 0.3s ease-in, visibility 0.3s ease-in;

  .solutions-expand-container {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;

    .solutions-container-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 350px;
      height: 100%;
      padding: 1rem 0rem;
      padding-left: min(1vw, 35px);
      padding-right: 1rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .solutions-container-data {
      width: 100%;
      height: 100%;
      padding: 1rem;

      .data-header {
        display: flex;
        flex-direction: column;
        height: 15%;
        margin-top: 5px;
        //border-bottom: 1px solid $white-400;

        h6 {
          font-size: 14px;
          font-family: "nb_international_proregular";
          margin-bottom: 0;
        }

        p {
          display: flex;
          height: 100%;
          align-items: center;
          font-size: 22px;
          font-family: "nb_international_proregular";
        }
      }

      .data-items {
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(5, 1fr);
        grid-auto-flow: column;
        height: 65%;
        border-bottom: 1px solid $white-400;

        a {
          font-family: "nb_international_proregular";
          font-size: 14px;
          text-decoration: none;
          align-self: center;
          justify-self: start;
          cursor: pointer;

          &:hover {
            color: $primary;
            text-decoration: underline;
            text-underline-offset: 0.4em;
            scale: 1.01;
          }
        }
      }

      .data-footer {
        display: flex;
        column-gap: 2rem;
        height: calc(20% - 5px);
        align-items: center;
        border-bottom: 1px solid $white-400;

        p {
          font-size: 18px;
          font-family: "nb_international_proregular";
        }

        a#footer_inquiry_button {
          width: 217px;
          height: 40px;
          background-color: $primary;
          border-radius: 2px;
          text-decoration: none;

          .inquiry-button {
            position: relative;
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
            padding: 12px;
            font-size: 14px;
            color: $white-200;
            transition: transform 0.3s ease-in-out;
            justify-content: center;

            span {
              transform: translateX(0);
              transition: transform 0.3s ease-in-out;
            }

            img,i {
              position: absolute;
              right: 12px;
              opacity: 0;
              visibility: hidden;
              transition: opacity 0.3s ease-in-out, visibility 0.3s;
            }
            
          }

          &:hover .inquiry-button {
            span {
              transform: translateX(-30%);
            }

            img,i {
              opacity: 1;
              visibility: visible;
              transition: opacity 0.3s ease-in-out, visibility 0.3s;
            }
          }
        }
      }
    }
  }
}

.header-with-navbar#enable_navbar_animation {
  z-index: calc($navbar-z-index + 1);
}

.header-with-navbar.enable-navbar-bg-color {
  background-color: $white-200;
}

.hide-navbar#home_navbar {
  display: none;
}

.show-navbar#home_navbar {
  display: block;
}

.navbar-data#animate_nav_data {
  opacity: 0;
}

// /* Initially show expandable SOLUTIONS menu only when hovering over the SOLUTIONS item */
// .navbar-menu-list-item#nav_menu_item_solutions:hover
//   + .navbar-item-expand#nav_menu_item_solutions_expand {
//   visibility: visible;
//   opacity: 1;
// }

@media screen and (max-width: 1023px) {
  .navbar-container {
    height: $navbar-height-mv;
  }

  .navbar-logo {
    width: 20px;
    left: 25px;
  }

  .navbar-actions {
    right: 25px;

    .nav_search_action_icon,
    .nav_language_toggle_icon {
      display: none;
    }

    .search-action,
    .language-change {
      display: none;
    }
  }

  .navbar-menu {
    display: none;
  }

  .navbar-item-solutions-expand {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .navbar-menu-list {
    column-gap: 1.25rem;
  }

  .navbar-actions {
    .search-action {
      .nav-search-form-input {
        width: 20vw;
        min-width: 200px;
      }
    }
  }
}

@media screen and (min-width: 1536px) {
  .navbar-menu-list {
    column-gap: 2rem;
  }
}

// .navbar-hover-effect {
//   position: absolute;
//   z-index: $nav-hover-effect-z-index;
//   height: $navbar-height;
//   width: 100%;
//   top: 0;
//   left: 0;
//   background-color: $redcarpet-100;
//   transition: background-color 0.3s ease-in-out;
// }